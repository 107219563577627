/* @generated */
// prettier-ignore
if (Intl.DisplayNames && typeof Intl.DisplayNames.__addLocaleData === 'function') {
  Intl.DisplayNames.__addLocaleData({
  "data": {
    "patterns": {
      "locale": "{0} ({1})"
    },
    "types": {
      "calendar": {
        "long": {
          "buddhist": "לוח השנה הבודהיסטי",
          "chinese": "לוח השנה הסיני",
          "coptic": "לוח שנה קופטי",
          "dangi": "לוח השנה הקוריאני",
          "ethiopic": "לוח השנה האתיופי",
          "ethiopic-amete-alem": "לוח השנה אמטה אלם האתיופי",
          "gregorian": "לוח השנה הגרגוריאני",
          "hebrew": "לוח השנה העברי",
          "indian": "לוח השנה ההודי הלאומי",
          "islamic": "לוח השנה המוסלמי",
          "islamic-civil": "לוח השנה המוסלמי האזרחי",
          "islamic-rgsa": "לוח השנה המוסלמי (ערב הסעודית)",
          "islamic-tbla": "לוח השנה המוסלמי האסטרולוגי",
          "islamic-umalqura": "לוח השנה המוסלמי אום אל-קורא",
          "iso8601": "לוח שנה ISO-8601",
          "japanese": "לוח השנה היפני",
          "persian": "לוח השנה הפרסי",
          "roc": "לוח השנה הטייוואני"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "currency": {
        "long": {
          "ADP": "פזטה אנדורית",
          "AED": "דירהם של איחוד הנסיכויות הערביות",
          "AFA": "AFA",
          "AFN": "אפגני אפגני",
          "ALK": "ALK",
          "ALL": "לק אלבני",
          "AMD": "דראם ארמני",
          "ANG": "גילדר של האנטילים ההולנדיים",
          "AOA": "קואנזה אנגולי",
          "AOK": "AOK",
          "AON": "קואנזה חדש אנגולי (1990–2000)",
          "AOR": "קואנזה רג׳וסטדו אנגולי (1995–1999)",
          "ARA": "ARA",
          "ARL": "ARL",
          "ARM": "ARM",
          "ARP": "פזו ארגנטינאי (1983–1985)",
          "ARS": "פסו ארגנטינאי",
          "ATS": "שילינג אוסטרי",
          "AUD": "דולר אוסטרלי",
          "AWG": "פלורין של ארובה",
          "AZM": "מנאט אזרביג׳אני (1993–2006)",
          "AZN": "מאנאט אזרבייג׳ני",
          "BAD": "דינר של בוסניה־הרצגובינה",
          "BAM": "מארק סחיר של בוסניה והרצגובינה",
          "BAN": "BAN",
          "BBD": "דולר ברבדיאני",
          "BDT": "טאקה בנגלדשי",
          "BEC": "פרנק בלגי (בר המרה)",
          "BEF": "פרנק בלגי",
          "BEL": "BEL",
          "BGL": "לב בולגרי ישן",
          "BGM": "BGM",
          "BGN": "לב בולגרי",
          "BGO": "BGO",
          "BHD": "דינר בחרייני",
          "BIF": "פרנק בורונדי",
          "BMD": "דולר ברמודה",
          "BND": "דולר ברוניי",
          "BOB": "בוליביאנו",
          "BOL": "BOL",
          "BOP": "פזו בוליבי",
          "BOV": "BOV",
          "BRB": "קרוזיארו חדש ברזילאי (1967–1986)",
          "BRC": "קרוזדו ברזילאי",
          "BRE": "BRE",
          "BRL": "ריאל ברזילאי",
          "BRN": "BRN",
          "BRR": "BRR",
          "BRZ": "BRZ",
          "BSD": "דולר בהאמי",
          "BTN": "נגולטרום בהוטני",
          "BUK": "BUK",
          "BWP": "פולה בוטסואני",
          "BYB": "BYB",
          "BYN": "רובל בלרוסי",
          "BYR": "רובל בלרוסי (2000–2016)",
          "BZD": "דולר בליזי",
          "CAD": "דולר קנדי",
          "CDF": "פרנק קונגולזי",
          "CHE": "CHE",
          "CHF": "פרנק שוויצרי",
          "CHW": "CHW",
          "CLE": "CLE",
          "CLF": "CLF",
          "CLP": "פסו צ׳ילאני",
          "CNH": "יואן סיני (CNH)",
          "CNX": "CNX",
          "CNY": "יואן סיני",
          "COP": "פסו קולומביאני",
          "COU": "COU",
          "CRC": "קולון קוסטה־ריקני",
          "CSD": "דינר סרבי ישן",
          "CSK": "CSK",
          "CUC": "פסו קובני להמרה",
          "CUP": "פסו קובני",
          "CVE": "אסקודו כף ורדה",
          "CYP": "לירה קפריסאית",
          "CZK": "קורונה צ׳כית",
          "DDM": "מרק מזרח גרמני",
          "DEM": "מרק גרמני",
          "DJF": "פרנק ג׳יבוטי",
          "DKK": "כתר דני",
          "DOP": "פסו דומיניקני",
          "DZD": "דינר אלג׳ירי",
          "ECS": "סוקר אקואדורי",
          "ECV": "ECV",
          "EEK": "קרון אסטוני",
          "EGP": "לירה מצרית",
          "ERN": "נאקפה אריתראי",
          "ESA": "ESA",
          "ESB": "ESB",
          "ESP": "פסטה ספרדי",
          "ETB": "ביר אתיופי",
          "EUR": "אירו",
          "FIM": "מרק פיני",
          "FJD": "דולר פיג׳י",
          "FKP": "לירה של איי פוקלנד",
          "FRF": "פרנק צרפתי",
          "GBP": "לירה שטרלינג",
          "GEK": "GEK",
          "GEL": "לארי גאורגי",
          "GHC": "GHC",
          "GHS": "סדי גאני",
          "GIP": "פאונד גיברלטר",
          "GMD": "דלסי גמבי",
          "GNF": "פרנק גינאי",
          "GNS": "GNS",
          "GQE": "GQE",
          "GRD": "דרכמה",
          "GTQ": "קצאל גואטמלי",
          "GWE": "GWE",
          "GWP": "פזו גינאי",
          "GYD": "דולר גיאני",
          "HKD": "דולר הונג קונגי",
          "HNL": "למפירה הונדורי",
          "HRD": "HRD",
          "HRK": "קונה קרואטי",
          "HTG": "גורד האיטי",
          "HUF": "פורינט הונגרי",
          "IDR": "רופיה אינדונזית",
          "IEP": "לירה אירית",
          "ILP": "לירה ישראלית",
          "ILR": "ILR",
          "ILS": "שקל חדש",
          "INR": "רופי הודי",
          "IQD": "דינר עיראקי",
          "IRR": "ריאל איראני",
          "ISJ": "ISJ",
          "ISK": "כתר איסלנדי",
          "ITL": "לירה איטלקית",
          "JMD": "דולר ג׳מייקני",
          "JOD": "דינר ירדני",
          "JPY": "ין יפני",
          "KES": "שילינג קנייתי",
          "KGS": "סום קירגיזי",
          "KHR": "ריל קמבודי",
          "KMF": "פרנק קומורואי",
          "KPW": "וון צפון קוריאני",
          "KRH": "KRH",
          "KRO": "KRO",
          "KRW": "וון דרום קוריאני",
          "KWD": "דינר כוויתי",
          "KYD": "דולר קיימני",
          "KZT": "טנגה קזחסטני",
          "LAK": "קיפ לאי",
          "LBP": "לירה לבנונית",
          "LKR": "רופי סרי לנקי",
          "LRD": "דולר ליברי",
          "LSL": "לוטי לסותי",
          "LTL": "ליטא ליטאי",
          "LTT": "LTT",
          "LUC": "LUC",
          "LUF": "פרנק לוקסמבורגי",
          "LUL": "LUL",
          "LVL": "לט לטבי",
          "LVR": "LVR",
          "LYD": "דינר לובי",
          "MAD": "דירהם מרוקאי",
          "MAF": "פרנק מרוקאי",
          "MCF": "MCF",
          "MDC": "MDC",
          "MDL": "לאו מולדובני",
          "MGA": "אריארי מלגשי",
          "MGF": "פרנק מדגסקארי",
          "MKD": "דינר מקדוני",
          "MKN": "MKN",
          "MLF": "MLF",
          "MMK": "קיאט מיאנמרי",
          "MNT": "טוגרוג מונגולי",
          "MOP": "פטקה של מקאו",
          "MRO": "אואוגויה מאוריטני (1973–2017)",
          "MRU": "אואוגויה מאוריטני",
          "MTL": "לירה מלטית",
          "MTP": "MTP",
          "MUR": "רופי מאוריציני",
          "MVP": "MVP",
          "MVR": "רופיה מלדיבית",
          "MWK": "קואצ׳ה מלאווי",
          "MXN": "פסו מקסיקני",
          "MXP": "פזו מקסיקני (1861 – 1992)",
          "MXV": "יחידת השקעות מקסיקנית",
          "MYR": "רינגיט מלזי",
          "MZE": "אסקודו מוזמביקי",
          "MZM": "מטיקל",
          "MZN": "מטיקל מוזמביני",
          "NAD": "דולר נמיבי",
          "NGN": "נאירה ניגרי",
          "NIC": "קורדובה (1988–1991)",
          "NIO": "קורדובה ניקרגואה",
          "NLG": "גילדן הולנדי",
          "NOK": "כתר נורווגי",
          "NPR": "רופי נפאלי",
          "NZD": "דולר ניו זילנדי",
          "OMR": "ריאל עומאני",
          "PAB": "בלבואה פנמי",
          "PEI": "PEI",
          "PEN": "סול פרואני",
          "PES": "PES",
          "PGK": "קינה של פפואה גינאה החדשה",
          "PHP": "פזו פיליפיני",
          "PKR": "רופי פקיסטני",
          "PLN": "זלוטי פולני",
          "PLZ": "PLZ",
          "PTE": "אסקודו פורטוגלי",
          "PYG": "גוארני פרגוואי",
          "QAR": "ריאל קטארי",
          "RHD": "RHD",
          "ROL": "לאו רומני ישן",
          "RON": "לאו רומני",
          "RSD": "דינר סרבי",
          "RUB": "רובל רוסי",
          "RUR": "רובל רוסי (1991 – 1998)",
          "RWF": "פרנק רואנדי",
          "SAR": "ריאל סעודי",
          "SBD": "דולר איי שלמה",
          "SCR": "רופי סיישלי",
          "SDD": "דינר סודני",
          "SDG": "לירה סודנית",
          "SDP": "לירה סודנית (1957–1998)",
          "SEK": "כתר שוודי",
          "SGD": "דולר סינגפורי",
          "SHP": "פאונד סנט הלני",
          "SIT": "טולאר סלובני",
          "SKK": "קורונה סלובקי",
          "SLL": "ליאון סיירה לאוני",
          "SOS": "שילינג סומלי",
          "SRD": "דולר סורינאמי",
          "SRG": "גילדר סורינאמי",
          "SSP": "לירה דרום-סודנית",
          "STD": "דוברה של סן טומה ופרינסיפה (1977–2017)",
          "STN": "דוברה של סאו טומה ופרינסיפה",
          "SUR": "רובל סובייטי",
          "SVC": "קולון סלבדורי",
          "SYP": "לירה סורית",
          "SZL": "לילנגני סווזילנדי",
          "THB": "בהט תאילנדי",
          "TJR": "TJR",
          "TJS": "סומוני טג׳קיסטני",
          "TMM": "מנאט טורקמאני",
          "TMT": "מאנאט טורקמני",
          "TND": "דינר טוניסאי",
          "TOP": "פאנגה טונגי",
          "TPE": "אסקודו טימוראי",
          "TRL": "לירה טורקית",
          "TRY": "לירה טורקית חדשה",
          "TTD": "דולר טרינידדי",
          "TWD": "דולר טייוואני חדש",
          "TZS": "שילינג טנזני",
          "UAH": "הריבנה אוקראיני",
          "UAK": "UAK",
          "UGS": "שילינג אוגנדי (1966 – 1987)",
          "UGX": "שילינג אוגנדי",
          "USD": "דולר אמריקאי",
          "USN": "דולר אמריקאי (היום הבא)",
          "USS": "דולר אמריקאי (היום הזה)",
          "UYI": "UYI",
          "UYP": "UYP",
          "UYU": "פסו אורוגוואי",
          "UYW": "UYW",
          "UZS": "סום אוזבקי",
          "VEB": "בוליבר ונצואלי (1871–2008)",
          "VEF": "בוליבר ונצואלי (2008–2018)",
          "VES": "בוליבר ונצואלי",
          "VND": "דונג וייטנאמי",
          "VNN": "VNN",
          "VUV": "ואטו של ונואטו",
          "WST": "טאלה סמואי",
          "XAF": "פרנק CFA מרכז אפריקני",
          "XAG": "כסף",
          "XAU": "זהב",
          "XBA": "XBA",
          "XBB": "XBB",
          "XBC": "XBC",
          "XBD": "XBD",
          "XCD": "דולר מזרח קריבי",
          "XDR": "זכויות משיכה מיוחדות",
          "XEU": "XEU",
          "XFO": "פרנק זהב",
          "XFU": "XFU",
          "XOF": "פרנק CFA מערב אפריקני",
          "XPD": "פלדיום",
          "XPF": "פרנק פולינזיה הצרפתית",
          "XPT": "פלטינה",
          "XRE": "XRE",
          "XSU": "XSU",
          "XTS": "סימון למטרות בדיקה",
          "XUA": "XUA",
          "XXX": "מטבע שאינו ידוע",
          "YDD": "דינר תימני",
          "YER": "ריאל תימני",
          "YUD": "YUD",
          "YUM": "YUM",
          "YUN": "YUN",
          "YUR": "YUR",
          "ZAL": "ראנד דרום אפריקאי (כספי)",
          "ZAR": "ראנד דרום אפריקאי",
          "ZMK": "קוואצ׳ה זמבית (1968–2012)",
          "ZMW": "קוואצ׳ה זמבי",
          "ZRN": "זאיר חדש",
          "ZRZ": "ZRZ",
          "ZWD": "דולר זימבבואי",
          "ZWL": "ZWL",
          "ZWR": "ZWR"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "dateTimeField": {
        "long": {
          "day": "יום",
          "dayOfYear": "יום בשנה",
          "dayperiod": "לפנה״צ/אחה״צ",
          "era": "תקופה",
          "hour": "שעה",
          "minute": "דקה",
          "month": "חודש",
          "quarter": "רבעון",
          "second": "שנייה",
          "timeZoneName": "אזור זמן",
          "weekOfMonth": "השבוע בחודש",
          "weekOfYear": "שבוע",
          "weekday": "יום בשבוע",
          "weekdayOfMonth": "יום חול בחודש",
          "year": "שנה"
        },
        "narrow": {
          "day": "יום",
          "dayOfYear": "יום בשנה",
          "dayperiod": "AM/PM",
          "era": "תקופה",
          "hour": "שע׳",
          "minute": "דק׳",
          "month": "חו׳",
          "quarter": "רבע׳",
          "second": "שנ׳",
          "timeZoneName": "אזור",
          "weekOfMonth": "השבוע בחודש",
          "weekOfYear": "שב׳",
          "weekday": "יום בשבוע",
          "weekdayOfMonth": "יום בחודש",
          "year": "שנ׳"
        },
        "short": {
          "day": "יום",
          "dayOfYear": "יום בשנה",
          "dayperiod": "AM/PM",
          "era": "תקופה",
          "hour": "שעה",
          "minute": "דק׳",
          "month": "חו׳",
          "quarter": "רבע׳",
          "second": "שנ׳",
          "timeZoneName": "אזור",
          "weekOfMonth": "השבוע בחודש",
          "weekOfYear": "שב׳",
          "weekday": "יום בשבוע",
          "weekdayOfMonth": "יום בחודש",
          "year": "שנ׳"
        }
      },
      "language": {
        "dialect": {
          "long": {
            "aa": "אפארית",
            "ab": "אבחזית",
            "ace": "אכינזית",
            "ach": "אקצ׳ולי",
            "ada": "אדנמה",
            "ady": "אדיגית",
            "ae": "אבסטן",
            "af": "אפריקאנס",
            "afh": "אפריהילי",
            "agq": "אע׳ם",
            "ain": "אינו",
            "ak": "אקאן",
            "akk": "אכדית",
            "ale": "אלאוט",
            "alt": "אלטאי דרומית",
            "am": "אמהרית",
            "an": "אראגונית",
            "ang": "אנגלית עתיקה",
            "anp": "אנג׳יקה",
            "ar": "ערבית",
            "ar-001": "ערבית ספרותית",
            "arc": "ארמית",
            "arn": "אראוקנית",
            "arp": "אראפהו",
            "ars": "ערבית - נג׳ד",
            "arw": "ארוואק",
            "as": "אסאמית",
            "asa": "אסו",
            "ast": "אסטורית",
            "av": "אווארית",
            "awa": "אוואדית",
            "ay": "איימארית",
            "az": "אזרית",
            "ba": "בשקירית",
            "bal": "באלוצ׳י",
            "ban": "באלינזית",
            "bar": "בווארית",
            "bas": "בסאא",
            "bax": "במום",
            "bbj": "גומאלה",
            "be": "בלארוסית",
            "bej": "בז׳ה",
            "bem": "במבה",
            "bez": "בנה",
            "bfd": "באפוט",
            "bg": "בולגרית",
            "bgn": "באלוצ׳י מערבית",
            "bho": "בוג׳פורי",
            "bi": "ביסלמה",
            "bik": "ביקול",
            "bin": "ביני",
            "bkm": "קום",
            "bla": "סיקסיקה",
            "bm": "במבארה",
            "bn": "בנגלית",
            "bo": "טיבטית",
            "br": "ברטונית",
            "bra": "בראג׳",
            "brx": "בודו",
            "bs": "בוסנית",
            "bss": "אקוסה",
            "bua": "בוריאט",
            "bug": "בוגינזית",
            "bum": "בולו",
            "byn": "בלין",
            "byv": "מדומבה",
            "ca": "קטלאנית",
            "cad": "קאדו",
            "car": "קאריב",
            "cay": "קאיוגה",
            "cch": "אטסם",
            "ccp": "צ׳אקמה",
            "ce": "צ׳צ׳נית",
            "ceb": "סבואנו",
            "cgg": "צ׳יגה",
            "ch": "צ׳מורו",
            "chb": "צ׳יבצ׳ה",
            "chg": "צ׳אגאטאי",
            "chk": "צ׳וקסה",
            "chm": "מארי",
            "chn": "ניב צ׳ינוק",
            "cho": "צ׳וקטאו",
            "chp": "צ׳יפוויאן",
            "chr": "צ׳רוקי",
            "chy": "שאיין",
            "ckb": "כורדית סוראנית",
            "co": "קורסיקנית",
            "cop": "קופטית",
            "cr": "קרי",
            "crh": "טטרית של קרים",
            "crs": "קריאולית (סיישל)",
            "cs": "צ׳כית",
            "csb": "קשובית",
            "cu": "סלאבית כנסייתית עתיקה",
            "cv": "צ׳ובאש",
            "cy": "וולשית",
            "da": "דנית",
            "dak": "דקוטה",
            "dar": "דרגווה",
            "dav": "טאיטה",
            "de": "גרמנית",
            "de-AT": "גרמנית (אוסטריה)",
            "de-CH": "גרמנית (שוויץ)",
            "del": "דלאוור",
            "den": "סלאבית",
            "dgr": "דוגריב",
            "din": "דינקה",
            "dje": "זארמה",
            "doi": "דוגרי",
            "dsb": "סורבית תחתית",
            "dua": "דואלה",
            "dum": "הולנדית תיכונה",
            "dv": "דיבהי",
            "dyo": "ג׳ולה פונית",
            "dyu": "דיולה",
            "dz": "דזונקה",
            "dzg": "דזאנגה",
            "ebu": "אמבו",
            "ee": "אווה",
            "efi": "אפיק",
            "egy": "מצרית עתיקה",
            "eka": "אקיוק",
            "el": "יוונית",
            "elx": "עילמית",
            "en": "אנגלית",
            "en-AU": "אנגלית (אוסטרליה)",
            "en-CA": "אנגלית (קנדה)",
            "en-GB": "אנגלית (בריטניה)",
            "en-US": "אנגלית (ארצות הברית)",
            "enm": "אנגלית תיכונה",
            "eo": "אספרנטו",
            "es": "ספרדית",
            "es-419": "ספרדית (אמריקה הלטינית)",
            "es-ES": "ספרדית (ספרד)",
            "es-MX": "ספרדית (מקסיקו)",
            "et": "אסטונית",
            "eu": "בסקית",
            "ewo": "אוונדו",
            "fa": "פרסית",
            "fa-AF": "דארי",
            "fan": "פנג",
            "fat": "פאנטי",
            "ff": "פולה",
            "fi": "פינית",
            "fil": "פיליפינית",
            "fj": "פיג׳ית",
            "fo": "פארואזית",
            "fon": "פון",
            "fr": "צרפתית",
            "fr-CA": "צרפתית (קנדה)",
            "fr-CH": "צרפתית (שוויץ)",
            "frc": "צרפתית קייג׳ונית",
            "frm": "צרפתית תיכונה",
            "fro": "צרפתית עתיקה",
            "frr": "פריזית צפונית",
            "frs": "פריזית מזרחית",
            "fur": "פריולית",
            "fy": "פריזית מערבית",
            "ga": "אירית",
            "gaa": "גא",
            "gag": "גגאוזית",
            "gan": "סינית גאן",
            "gay": "גאיו",
            "gba": "גבאיה",
            "gd": "גאלית סקוטית",
            "gez": "געז",
            "gil": "קיריבטית",
            "gl": "גליציאנית",
            "gmh": "גרמנית בינונית-גבוהה",
            "gn": "גוארני",
            "goh": "גרמנית עתיקה גבוהה",
            "gon": "גונדי",
            "gor": "גורונטאלו",
            "got": "גותית",
            "grb": "גרבו",
            "grc": "יוונית עתיקה",
            "gsw": "גרמנית שוויצרית",
            "gu": "גוג׳ארטי",
            "guz": "גוסי",
            "gv": "מאנית",
            "gwi": "גוויצ׳ן",
            "ha": "האוסה",
            "hai": "האידה",
            "hak": "סינית האקה",
            "haw": "הוואית",
            "he": "עברית",
            "hi": "הינדי",
            "hil": "היליגאינון",
            "hit": "חתית",
            "hmn": "המונג",
            "ho": "הירי מוטו",
            "hr": "קרואטית",
            "hsb": "סורבית עילית",
            "hsn": "סינית שיאנג",
            "ht": "קריאולית (האיטי)",
            "hu": "הונגרית",
            "hup": "הופה",
            "hy": "ארמנית",
            "hz": "הררו",
            "ia": "‏אינטרלינגואה",
            "iba": "איבאן",
            "ibb": "איביביו",
            "id": "אינדונזית",
            "ie": "אינטרלינגה",
            "ig": "איגבו",
            "ii": "סצ׳ואן יי",
            "ik": "אינופיאק",
            "ilo": "אילוקו",
            "inh": "אינגושית",
            "io": "אידו",
            "is": "איסלנדית",
            "it": "איטלקית",
            "iu": "אינוקטיטוט",
            "ja": "יפנית",
            "jbo": "לוז׳באן",
            "jgo": "נגומבה",
            "jmc": "מאקאמה",
            "jpr": "פרסית יהודית",
            "jrb": "ערבית יהודית",
            "jv": "יאוואית",
            "ka": "גאורגית",
            "kaa": "קארא-קלפאק",
            "kab": "קבילה",
            "kac": "קצ׳ין",
            "kaj": "ג׳ו",
            "kam": "קמבה",
            "kaw": "קאווי",
            "kbd": "קברדית",
            "kbl": "קנמבו",
            "kcg": "טיאפ",
            "kde": "מקונדה",
            "kea": "קאבוורדיאנו",
            "kfo": "קורו",
            "kg": "קונגו",
            "kgp": "קיינגאנג",
            "kha": "קהאסי",
            "kho": "קוטאנזית",
            "khq": "קוירה צ׳יני",
            "ki": "קיקויו",
            "kj": "קואניאמה",
            "kk": "קזחית",
            "kkj": "קאקו",
            "kl": "גרינלנדית",
            "kln": "קלנג׳ין",
            "km": "חמרית",
            "kmb": "קימבונדו",
            "kn": "קנאדה",
            "ko": "קוריאנית",
            "koi": "קומי-פרמיאקית",
            "kok": "קונקאני",
            "kos": "קוסראיאן",
            "kpe": "קפלה",
            "kr": "קאנורי",
            "krc": "קראצ׳י-בלקר",
            "krl": "קארלית",
            "kru": "קורוק",
            "ks": "קשמירית",
            "ksb": "שמבאלה",
            "ksf": "באפיה",
            "ksh": "קולוניאן",
            "ku": "כורדית",
            "kum": "קומיקית",
            "kut": "קוטנאי",
            "kv": "קומי",
            "kw": "קורנית",
            "ky": "קירגיזית",
            "la": "לטינית",
            "lad": "לדינו",
            "lag": "לאנגי",
            "lah": "לנדה",
            "lam": "למבה",
            "lb": "לוקסמבורגית",
            "lez": "לזגית",
            "lg": "גאנדה",
            "li": "לימבורגית",
            "lij": "ליגורית",
            "lkt": "לקוטה",
            "ln": "לינגלה",
            "lo": "לאו",
            "lol": "מונגו",
            "lou": "קריאולית לואיזיאנית",
            "loz": "לוזית",
            "lrc": "לורית צפונית",
            "lt": "ליטאית",
            "lu": "לובה-קטנגה",
            "lua": "לובה-לולואה",
            "lui": "לויסנו",
            "lun": "לונדה",
            "luo": "לואו",
            "lus": "מיזו",
            "luy": "לויה",
            "lv": "לטבית",
            "mad": "מדורזית",
            "maf": "מאפאה",
            "mag": "מאגאהית",
            "mai": "מאיטילית",
            "mak": "מקסאר",
            "man": "מנדינגו",
            "mas": "מסאית",
            "mde": "מאבא",
            "mdf": "מוקשה",
            "mdr": "מנדאר",
            "men": "מנדה",
            "mer": "מרו",
            "mfe": "קריאולית מאוריציאנית",
            "mg": "מלגשית",
            "mga": "אירית תיכונה",
            "mgh": "מאקוואה מטו",
            "mgo": "מטא",
            "mh": "מרשלית",
            "mi": "מאורית",
            "mic": "מיקמק",
            "min": "מיננגקבאו",
            "mk": "מקדונית",
            "ml": "מליאלאם",
            "mn": "מונגולית",
            "mnc": "מנצ׳ו",
            "mni": "מניפורית",
            "moh": "מוהוק",
            "mos": "מוסי",
            "mr": "מראטהי",
            "ms": "מלאית",
            "mt": "מלטית",
            "mua": "מונדאנג",
            "mul": "מספר שפות",
            "mus": "קריק",
            "mwl": "מירנדזית",
            "mwr": "מרווארי",
            "my": "בורמזית",
            "mye": "מאיין",
            "myv": "ארזיה",
            "mzn": "מאזאנדראני",
            "na": "נאורית",
            "nan": "סינית מין נאן",
            "nap": "נפוליטנית",
            "naq": "נאמה",
            "nb": "נורווגית ספרותית",
            "nd": "נדבלה צפונית",
            "nds": "גרמנית תחתית",
            "nds-NL": "סקסונית תחתית",
            "ne": "נפאלית",
            "new": "נווארי",
            "ng": "נדונגה",
            "nia": "ניאס",
            "niu": "ניואן",
            "nl": "הולנדית",
            "nl-BE": "פלמית",
            "nmg": "קוואסיו",
            "nn": "נורווגית חדשה",
            "nnh": "נגיאמבון",
            "no": "נורווגית",
            "nog": "נוגאי",
            "non": "‏נורדית עתיקה",
            "nqo": "נ׳קו",
            "nr": "נדבלה דרומית",
            "nso": "סותו צפונית",
            "nus": "נואר",
            "nv": "נאוואחו",
            "nwc": "נווארית קלאסית",
            "ny": "ניאנג׳ה",
            "nym": "ניאמווזי",
            "nyn": "ניאנקולה",
            "nyo": "ניורו",
            "nzi": "נזימה",
            "oc": "אוקסיטנית",
            "oj": "אוג׳יבווה",
            "om": "אורומו",
            "or": "אורייה",
            "os": "אוסטית",
            "osa": "אוסג׳",
            "ota": "טורקית עות׳מנית",
            "pa": "פנג׳אבי",
            "pag": "פנגסינאן",
            "pal": "פלאבי",
            "pam": "פמפאניה",
            "pap": "פפיאמנטו",
            "pau": "פלוואן",
            "pcm": "ניגרית פידג׳ית",
            "peo": "פרסית עתיקה",
            "phn": "פיניקית",
            "pi": "פאלי",
            "pl": "פולנית",
            "pon": "פונפיאן",
            "prg": "פרוסית",
            "pro": "פרובנסאל עתיקה",
            "ps": "פאשטו",
            "pt": "פורטוגזית",
            "pt-BR": "פורטוגזית (ברזיל)",
            "pt-PT": "פורטוגזית (פורטוגל)",
            "qu": "קצ׳ואה",
            "quc": "קיצ׳ה",
            "raj": "ראג׳סטאני",
            "rap": "רפאנוי",
            "rar": "ררוטונגאן",
            "rhg": "רוהינגיה",
            "rm": "רומאנש",
            "rn": "קירונדי",
            "ro": "רומנית",
            "ro-MD": "מולדבית",
            "rof": "רומבו",
            "rom": "רומאני",
            "ru": "רוסית",
            "rup": "ארומנית",
            "rw": "קנירואנדית",
            "rwk": "ראווה",
            "sa": "סנסקריט",
            "sad": "סנדאווה",
            "sah": "סאחה",
            "sam": "ארמית שומרונית",
            "saq": "סמבורו",
            "sas": "סאסק",
            "sat": "סאנטאלי",
            "sba": "נגמבאי",
            "sbp": "סאנגו",
            "sc": "סרדינית",
            "scn": "סיציליאנית",
            "sco": "סקוטית",
            "sd": "סינדהית",
            "sdh": "כורדית דרומית",
            "se": "סמי צפונית",
            "see": "סנקה",
            "seh": "סנה",
            "sel": "סלקופ",
            "ses": "קויראבורו סני",
            "sg": "סנגו",
            "sga": "אירית עתיקה",
            "sh": "סרבו-קרואטית",
            "shi": "שילה",
            "shn": "שאן",
            "shu": "ערבית צ׳אדית",
            "si": "סינהלה",
            "sid": "סידאמו",
            "sk": "סלובקית",
            "sl": "סלובנית",
            "sm": "סמואית",
            "sma": "סאמי דרומית",
            "smj": "לולה סאמי",
            "smn": "אינארי סאמי",
            "sms": "סקולט סאמי",
            "sn": "שונה",
            "snk": "סונינקה",
            "so": "סומלית",
            "sog": "סוגדיאן",
            "sq": "אלבנית",
            "sr": "סרבית",
            "srn": "סרנאן טונגו",
            "srr": "סרר",
            "ss": "סאווזי",
            "ssy": "סאהו",
            "st": "סותו דרומית",
            "su": "סונדנזית",
            "suk": "סוקומה",
            "sus": "סוסו",
            "sux": "שומרית",
            "sv": "שוודית",
            "sw": "סווהילי",
            "sw-CD": "סווהילי קונגו",
            "swb": "קומורית",
            "syc": "סירית קלאסית",
            "syr": "סורית",
            "ta": "טמילית",
            "te": "טלוגו",
            "tem": "טימנה",
            "teo": "טסו",
            "ter": "טרנו",
            "tet": "טטום",
            "tg": "טג׳יקית",
            "th": "תאית",
            "ti": "תיגרינית",
            "tig": "טיגרית",
            "tiv": "טיב",
            "tk": "טורקמנית",
            "tkl": "טוקלאו",
            "tl": "טאגאלוג",
            "tlh": "קלינגונית",
            "tli": "טלינגיט",
            "tmh": "טמאשק",
            "tn": "סוואנה",
            "to": "טונגאית",
            "tog": "ניאסה טונגה",
            "tpi": "טוק פיסין",
            "tr": "טורקית",
            "trv": "טרוקו",
            "ts": "טסונגה",
            "tsi": "טסימשיאן",
            "tt": "טטרית",
            "tum": "טומבוקה",
            "tvl": "טובאלו",
            "tw": "טווי",
            "twq": "טסוואק",
            "ty": "טהיטית",
            "tyv": "טובינית",
            "tzm": "תמאזיגת של מרכז מרוקו",
            "udm": "אודמורט",
            "ug": "אויגורית",
            "uga": "אוגריתית",
            "uk": "אוקראינית",
            "umb": "אומבונדו",
            "und": "שפה לא ידועה",
            "ur": "אורדו",
            "uz": "אוזבקית",
            "vai": "וואי",
            "ve": "וונדה",
            "vi": "וייטנאמית",
            "vo": "‏וולאפיק",
            "vot": "ווטיק",
            "vun": "וונג׳ו",
            "wa": "ולונית",
            "wae": "וואלסר",
            "wal": "ווליאטה",
            "war": "ווראי",
            "was": "וואשו",
            "wbp": "וורלפירי",
            "wo": "וולוף",
            "wuu": "סינית וו",
            "xal": "קלמיקית",
            "xh": "קוסה",
            "xog": "סוגה",
            "yao": "יאו",
            "yap": "יאפזית",
            "yav": "יאנגבן",
            "ybb": "ימבה",
            "yi": "יידיש",
            "yo": "יורובה",
            "yrl": "yrl",
            "yue": "קנטונזית",
            "za": "זואנג",
            "zap": "זאפוטק",
            "zbl": "בליסימבולס",
            "zen": "זנאגה",
            "zgh": "תמזיע׳ת מרוקאית תקנית",
            "zh": "סינית",
            "zh-Hans": "סינית פשוטה",
            "zh-Hant": "סינית מסורתית",
            "zu": "זולו",
            "zun": "זוני",
            "zxx": "ללא תוכן לשוני",
            "zza": "זאזא"
          },
          "narrow": {
          },
          "short": {
            "az": "אזרית",
            "en-GB": "אנגלית (בריטניה)",
            "en-US": "אנגלית (ארה״ב)"
          }
        },
        "standard": {
          "long": {
            "aa": "אפארית",
            "ab": "אבחזית",
            "ace": "אכינזית",
            "ach": "אקצ׳ולי",
            "ada": "אדנמה",
            "ady": "אדיגית",
            "ae": "אבסטן",
            "af": "אפריקאנס",
            "afh": "אפריהילי",
            "agq": "אע׳ם",
            "ain": "אינו",
            "ak": "אקאן",
            "akk": "אכדית",
            "ale": "אלאוט",
            "alt": "אלטאי דרומית",
            "am": "אמהרית",
            "an": "אראגונית",
            "ang": "אנגלית עתיקה",
            "anp": "אנג׳יקה",
            "ar": "ערבית",
            "ar-001": "ערבית (העולם)",
            "arc": "ארמית",
            "arn": "אראוקנית",
            "arp": "אראפהו",
            "ars": "ערבית - נג׳ד",
            "arw": "ארוואק",
            "as": "אסאמית",
            "asa": "אסו",
            "ast": "אסטורית",
            "av": "אווארית",
            "awa": "אוואדית",
            "ay": "איימארית",
            "az": "אזרית",
            "ba": "בשקירית",
            "bal": "באלוצ׳י",
            "ban": "באלינזית",
            "bar": "בווארית",
            "bas": "בסאא",
            "bax": "במום",
            "bbj": "גומאלה",
            "be": "בלארוסית",
            "bej": "בז׳ה",
            "bem": "במבה",
            "bez": "בנה",
            "bfd": "באפוט",
            "bg": "בולגרית",
            "bgn": "באלוצ׳י מערבית",
            "bho": "בוג׳פורי",
            "bi": "ביסלמה",
            "bik": "ביקול",
            "bin": "ביני",
            "bkm": "קום",
            "bla": "סיקסיקה",
            "bm": "במבארה",
            "bn": "בנגלית",
            "bo": "טיבטית",
            "br": "ברטונית",
            "bra": "בראג׳",
            "brx": "בודו",
            "bs": "בוסנית",
            "bss": "אקוסה",
            "bua": "בוריאט",
            "bug": "בוגינזית",
            "bum": "בולו",
            "byn": "בלין",
            "byv": "מדומבה",
            "ca": "קטלאנית",
            "cad": "קאדו",
            "car": "קאריב",
            "cay": "קאיוגה",
            "cch": "אטסם",
            "ccp": "צ׳אקמה",
            "ce": "צ׳צ׳נית",
            "ceb": "סבואנו",
            "cgg": "צ׳יגה",
            "ch": "צ׳מורו",
            "chb": "צ׳יבצ׳ה",
            "chg": "צ׳אגאטאי",
            "chk": "צ׳וקסה",
            "chm": "מארי",
            "chn": "ניב צ׳ינוק",
            "cho": "צ׳וקטאו",
            "chp": "צ׳יפוויאן",
            "chr": "צ׳רוקי",
            "chy": "שאיין",
            "ckb": "כורדית סוראנית",
            "co": "קורסיקנית",
            "cop": "קופטית",
            "cr": "קרי",
            "crh": "טטרית של קרים",
            "crs": "קריאולית (סיישל)",
            "cs": "צ׳כית",
            "csb": "קשובית",
            "cu": "סלאבית כנסייתית עתיקה",
            "cv": "צ׳ובאש",
            "cy": "וולשית",
            "da": "דנית",
            "dak": "דקוטה",
            "dar": "דרגווה",
            "dav": "טאיטה",
            "de": "גרמנית",
            "de-AT": "גרמנית (אוסטריה)",
            "de-CH": "גרמנית (שווייץ)",
            "del": "דלאוור",
            "den": "סלאבית",
            "dgr": "דוגריב",
            "din": "דינקה",
            "dje": "זארמה",
            "doi": "דוגרי",
            "dsb": "סורבית תחתית",
            "dua": "דואלה",
            "dum": "הולנדית תיכונה",
            "dv": "דיבהי",
            "dyo": "ג׳ולה פונית",
            "dyu": "דיולה",
            "dz": "דזונקה",
            "dzg": "דזאנגה",
            "ebu": "אמבו",
            "ee": "אווה",
            "efi": "אפיק",
            "egy": "מצרית עתיקה",
            "eka": "אקיוק",
            "el": "יוונית",
            "elx": "עילמית",
            "en": "אנגלית",
            "en-AU": "אנגלית (אוסטרליה)",
            "en-CA": "אנגלית (קנדה)",
            "en-GB": "אנגלית (בריטניה)",
            "en-US": "אנגלית (ארצות הברית)",
            "enm": "אנגלית תיכונה",
            "eo": "אספרנטו",
            "es": "ספרדית",
            "es-419": "ספרדית (אמריקה הלטינית)",
            "es-ES": "ספרדית (ספרד)",
            "es-MX": "ספרדית (מקסיקו)",
            "et": "אסטונית",
            "eu": "בסקית",
            "ewo": "אוונדו",
            "fa": "פרסית",
            "fa-AF": "פרסית (אפגניסטן)",
            "fan": "פנג",
            "fat": "פאנטי",
            "ff": "פולה",
            "fi": "פינית",
            "fil": "פיליפינית",
            "fj": "פיג׳ית",
            "fo": "פארואזית",
            "fon": "פון",
            "fr": "צרפתית",
            "fr-CA": "צרפתית (קנדה)",
            "fr-CH": "צרפתית (שווייץ)",
            "frc": "צרפתית קייג׳ונית",
            "frm": "צרפתית תיכונה",
            "fro": "צרפתית עתיקה",
            "frr": "פריזית צפונית",
            "frs": "פריזית מזרחית",
            "fur": "פריולית",
            "fy": "פריזית מערבית",
            "ga": "אירית",
            "gaa": "גא",
            "gag": "גגאוזית",
            "gan": "סינית גאן",
            "gay": "גאיו",
            "gba": "גבאיה",
            "gd": "גאלית סקוטית",
            "gez": "געז",
            "gil": "קיריבטית",
            "gl": "גליציאנית",
            "gmh": "גרמנית בינונית-גבוהה",
            "gn": "גוארני",
            "goh": "גרמנית עתיקה גבוהה",
            "gon": "גונדי",
            "gor": "גורונטאלו",
            "got": "גותית",
            "grb": "גרבו",
            "grc": "יוונית עתיקה",
            "gsw": "גרמנית שוויצרית",
            "gu": "גוג׳ארטי",
            "guz": "גוסי",
            "gv": "מאנית",
            "gwi": "גוויצ׳ן",
            "ha": "האוסה",
            "hai": "האידה",
            "hak": "סינית האקה",
            "haw": "הוואית",
            "he": "עברית",
            "hi": "הינדי",
            "hil": "היליגאינון",
            "hit": "חתית",
            "hmn": "המונג",
            "ho": "הירי מוטו",
            "hr": "קרואטית",
            "hsb": "סורבית עילית",
            "hsn": "סינית שיאנג",
            "ht": "קריאולית (האיטי)",
            "hu": "הונגרית",
            "hup": "הופה",
            "hy": "ארמנית",
            "hz": "הררו",
            "ia": "‏אינטרלינגואה",
            "iba": "איבאן",
            "ibb": "איביביו",
            "id": "אינדונזית",
            "ie": "אינטרלינגה",
            "ig": "איגבו",
            "ii": "סצ׳ואן יי",
            "ik": "אינופיאק",
            "ilo": "אילוקו",
            "inh": "אינגושית",
            "io": "אידו",
            "is": "איסלנדית",
            "it": "איטלקית",
            "iu": "אינוקטיטוט",
            "ja": "יפנית",
            "jbo": "לוז׳באן",
            "jgo": "נגומבה",
            "jmc": "מאקאמה",
            "jpr": "פרסית יהודית",
            "jrb": "ערבית יהודית",
            "jv": "יאוואית",
            "ka": "גאורגית",
            "kaa": "קארא-קלפאק",
            "kab": "קבילה",
            "kac": "קצ׳ין",
            "kaj": "ג׳ו",
            "kam": "קמבה",
            "kaw": "קאווי",
            "kbd": "קברדית",
            "kbl": "קנמבו",
            "kcg": "טיאפ",
            "kde": "מקונדה",
            "kea": "קאבוורדיאנו",
            "kfo": "קורו",
            "kg": "קונגו",
            "kgp": "קיינגאנג",
            "kha": "קהאסי",
            "kho": "קוטאנזית",
            "khq": "קוירה צ׳יני",
            "ki": "קיקויו",
            "kj": "קואניאמה",
            "kk": "קזחית",
            "kkj": "קאקו",
            "kl": "גרינלנדית",
            "kln": "קלנג׳ין",
            "km": "חמרית",
            "kmb": "קימבונדו",
            "kn": "קנאדה",
            "ko": "קוריאנית",
            "koi": "קומי-פרמיאקית",
            "kok": "קונקאני",
            "kos": "קוסראיאן",
            "kpe": "קפלה",
            "kr": "קאנורי",
            "krc": "קראצ׳י-בלקר",
            "krl": "קארלית",
            "kru": "קורוק",
            "ks": "קשמירית",
            "ksb": "שמבאלה",
            "ksf": "באפיה",
            "ksh": "קולוניאן",
            "ku": "כורדית",
            "kum": "קומיקית",
            "kut": "קוטנאי",
            "kv": "קומי",
            "kw": "קורנית",
            "ky": "קירגיזית",
            "la": "לטינית",
            "lad": "לדינו",
            "lag": "לאנגי",
            "lah": "לנדה",
            "lam": "למבה",
            "lb": "לוקסמבורגית",
            "lez": "לזגית",
            "lg": "גאנדה",
            "li": "לימבורגית",
            "lij": "ליגורית",
            "lkt": "לקוטה",
            "ln": "לינגלה",
            "lo": "לאו",
            "lol": "מונגו",
            "lou": "קריאולית לואיזיאנית",
            "loz": "לוזית",
            "lrc": "לורית צפונית",
            "lt": "ליטאית",
            "lu": "לובה-קטנגה",
            "lua": "לובה-לולואה",
            "lui": "לויסנו",
            "lun": "לונדה",
            "luo": "לואו",
            "lus": "מיזו",
            "luy": "לויה",
            "lv": "לטבית",
            "mad": "מדורזית",
            "maf": "מאפאה",
            "mag": "מאגאהית",
            "mai": "מאיטילית",
            "mak": "מקסאר",
            "man": "מנדינגו",
            "mas": "מסאית",
            "mde": "מאבא",
            "mdf": "מוקשה",
            "mdr": "מנדאר",
            "men": "מנדה",
            "mer": "מרו",
            "mfe": "קריאולית מאוריציאנית",
            "mg": "מלגשית",
            "mga": "אירית תיכונה",
            "mgh": "מאקוואה מטו",
            "mgo": "מטא",
            "mh": "מרשלית",
            "mi": "מאורית",
            "mic": "מיקמק",
            "min": "מיננגקבאו",
            "mk": "מקדונית",
            "ml": "מליאלאם",
            "mn": "מונגולית",
            "mnc": "מנצ׳ו",
            "mni": "מניפורית",
            "moh": "מוהוק",
            "mos": "מוסי",
            "mr": "מראטהי",
            "ms": "מלאית",
            "mt": "מלטית",
            "mua": "מונדאנג",
            "mul": "מספר שפות",
            "mus": "קריק",
            "mwl": "מירנדזית",
            "mwr": "מרווארי",
            "my": "בורמזית",
            "mye": "מאיין",
            "myv": "ארזיה",
            "mzn": "מאזאנדראני",
            "na": "נאורית",
            "nan": "סינית מין נאן",
            "nap": "נפוליטנית",
            "naq": "נאמה",
            "nb": "נורווגית ספרותית",
            "nd": "נדבלה צפונית",
            "nds": "גרמנית תחתית",
            "nds-NL": "גרמנית תחתית (הולנד)",
            "ne": "נפאלית",
            "new": "נווארי",
            "ng": "נדונגה",
            "nia": "ניאס",
            "niu": "ניואן",
            "nl": "הולנדית",
            "nl-BE": "הולנדית (בלגיה)",
            "nmg": "קוואסיו",
            "nn": "נורווגית חדשה",
            "nnh": "נגיאמבון",
            "no": "נורווגית",
            "nog": "נוגאי",
            "non": "‏נורדית עתיקה",
            "nqo": "נ׳קו",
            "nr": "נדבלה דרומית",
            "nso": "סותו צפונית",
            "nus": "נואר",
            "nv": "נאוואחו",
            "nwc": "נווארית קלאסית",
            "ny": "ניאנג׳ה",
            "nym": "ניאמווזי",
            "nyn": "ניאנקולה",
            "nyo": "ניורו",
            "nzi": "נזימה",
            "oc": "אוקסיטנית",
            "oj": "אוג׳יבווה",
            "om": "אורומו",
            "or": "אורייה",
            "os": "אוסטית",
            "osa": "אוסג׳",
            "ota": "טורקית עות׳מנית",
            "pa": "פנג׳אבי",
            "pag": "פנגסינאן",
            "pal": "פלאבי",
            "pam": "פמפאניה",
            "pap": "פפיאמנטו",
            "pau": "פלוואן",
            "pcm": "ניגרית פידג׳ית",
            "peo": "פרסית עתיקה",
            "phn": "פיניקית",
            "pi": "פאלי",
            "pl": "פולנית",
            "pon": "פונפיאן",
            "prg": "פרוסית",
            "pro": "פרובנסאל עתיקה",
            "ps": "פאשטו",
            "pt": "פורטוגזית",
            "pt-BR": "פורטוגזית (ברזיל)",
            "pt-PT": "פורטוגזית (פורטוגל)",
            "qu": "קצ׳ואה",
            "quc": "קיצ׳ה",
            "raj": "ראג׳סטאני",
            "rap": "רפאנוי",
            "rar": "ררוטונגאן",
            "rhg": "רוהינגיה",
            "rm": "רומאנש",
            "rn": "קירונדי",
            "ro": "רומנית",
            "ro-MD": "רומנית (מולדובה)",
            "rof": "רומבו",
            "rom": "רומאני",
            "ru": "רוסית",
            "rup": "ארומנית",
            "rw": "קנירואנדית",
            "rwk": "ראווה",
            "sa": "סנסקריט",
            "sad": "סנדאווה",
            "sah": "סאחה",
            "sam": "ארמית שומרונית",
            "saq": "סמבורו",
            "sas": "סאסק",
            "sat": "סאנטאלי",
            "sba": "נגמבאי",
            "sbp": "סאנגו",
            "sc": "סרדינית",
            "scn": "סיציליאנית",
            "sco": "סקוטית",
            "sd": "סינדהית",
            "sdh": "כורדית דרומית",
            "se": "סמי צפונית",
            "see": "סנקה",
            "seh": "סנה",
            "sel": "סלקופ",
            "ses": "קויראבורו סני",
            "sg": "סנגו",
            "sga": "אירית עתיקה",
            "sh": "סרבו-קרואטית",
            "shi": "שילה",
            "shn": "שאן",
            "shu": "ערבית צ׳אדית",
            "si": "סינהלה",
            "sid": "סידאמו",
            "sk": "סלובקית",
            "sl": "סלובנית",
            "sm": "סמואית",
            "sma": "סאמי דרומית",
            "smj": "לולה סאמי",
            "smn": "אינארי סאמי",
            "sms": "סקולט סאמי",
            "sn": "שונה",
            "snk": "סונינקה",
            "so": "סומלית",
            "sog": "סוגדיאן",
            "sq": "אלבנית",
            "sr": "סרבית",
            "srn": "סרנאן טונגו",
            "srr": "סרר",
            "ss": "סאווזי",
            "ssy": "סאהו",
            "st": "סותו דרומית",
            "su": "סונדנזית",
            "suk": "סוקומה",
            "sus": "סוסו",
            "sux": "שומרית",
            "sv": "שוודית",
            "sw": "סווהילי",
            "sw-CD": "סווהילי (קונגו - קינשאסה)",
            "swb": "קומורית",
            "syc": "סירית קלאסית",
            "syr": "סורית",
            "ta": "טמילית",
            "te": "טלוגו",
            "tem": "טימנה",
            "teo": "טסו",
            "ter": "טרנו",
            "tet": "טטום",
            "tg": "טג׳יקית",
            "th": "תאית",
            "ti": "תיגרינית",
            "tig": "טיגרית",
            "tiv": "טיב",
            "tk": "טורקמנית",
            "tkl": "טוקלאו",
            "tl": "טאגאלוג",
            "tlh": "קלינגונית",
            "tli": "טלינגיט",
            "tmh": "טמאשק",
            "tn": "סוואנה",
            "to": "טונגאית",
            "tog": "ניאסה טונגה",
            "tpi": "טוק פיסין",
            "tr": "טורקית",
            "trv": "טרוקו",
            "ts": "טסונגה",
            "tsi": "טסימשיאן",
            "tt": "טטרית",
            "tum": "טומבוקה",
            "tvl": "טובאלו",
            "tw": "טווי",
            "twq": "טסוואק",
            "ty": "טהיטית",
            "tyv": "טובינית",
            "tzm": "תמאזיגת של מרכז מרוקו",
            "udm": "אודמורט",
            "ug": "אויגורית",
            "uga": "אוגריתית",
            "uk": "אוקראינית",
            "umb": "אומבונדו",
            "und": "שפה לא ידועה",
            "ur": "אורדו",
            "uz": "אוזבקית",
            "vai": "וואי",
            "ve": "וונדה",
            "vi": "וייטנאמית",
            "vo": "‏וולאפיק",
            "vot": "ווטיק",
            "vun": "וונג׳ו",
            "wa": "ולונית",
            "wae": "וואלסר",
            "wal": "ווליאטה",
            "war": "ווראי",
            "was": "וואשו",
            "wbp": "וורלפירי",
            "wo": "וולוף",
            "wuu": "סינית וו",
            "xal": "קלמיקית",
            "xh": "קוסה",
            "xog": "סוגה",
            "yao": "יאו",
            "yap": "יאפזית",
            "yav": "יאנגבן",
            "ybb": "ימבה",
            "yi": "יידיש",
            "yo": "יורובה",
            "yrl": "yrl",
            "yue": "קנטונזית",
            "za": "זואנג",
            "zap": "זאפוטק",
            "zbl": "בליסימבולס",
            "zen": "זנאגה",
            "zgh": "תמזיע׳ת מרוקאית תקנית",
            "zh": "סינית",
            "zh-Hans": "סינית פשוטה",
            "zh-Hant": "סינית מסורתית",
            "zu": "זולו",
            "zun": "זוני",
            "zxx": "ללא תוכן לשוני",
            "zza": "זאזא"
          },
          "narrow": {
          },
          "short": {
            "az": "אזרית",
            "en-GB": "אנגלית (בריטניה)",
            "en-US": "אנגלית (ארה״ב)"
          }
        }
      },
      "region": {
        "long": {
          "001": "העולם",
          "002": "אפריקה",
          "003": "צפון אמריקה",
          "005": "דרום אמריקה",
          "009": "אוקיאניה",
          "011": "מערב אפריקה",
          "013": "מרכז אמריקה",
          "014": "מזרח אפריקה",
          "015": "צפון אפריקה",
          "017": "מרכז אפריקה",
          "018": "דרום יבשת אפריקה",
          "019": "אמריקה",
          "021": "אמריקה הצפונית",
          "029": "האיים הקריביים",
          "030": "מזרח אסיה",
          "034": "דרום אסיה",
          "035": "דרום־מזרח אסיה",
          "039": "דרום אירופה",
          "053": "אוסטרלאסיה",
          "054": "מלנזיה",
          "057": "אזור מיקרונזיה",
          "061": "פולינזיה",
          "142": "אסיה",
          "143": "מרכז אסיה",
          "145": "מערב אסיה",
          "150": "אירופה",
          "151": "מזרח אירופה",
          "154": "צפון אירופה",
          "155": "מערב אירופה",
          "202": "אפריקה שמדרום לסהרה",
          "419": "אמריקה הלטינית",
          "AC": "האי אסנשן",
          "AD": "אנדורה",
          "AE": "איחוד האמירויות הערביות",
          "AF": "אפגניסטן",
          "AG": "אנטיגואה וברבודה",
          "AI": "אנגווילה",
          "AL": "אלבניה",
          "AM": "ארמניה",
          "AO": "אנגולה",
          "AQ": "אנטארקטיקה",
          "AR": "ארגנטינה",
          "AS": "סמואה האמריקנית",
          "AT": "אוסטריה",
          "AU": "אוסטרליה",
          "AW": "ארובה",
          "AX": "איי אולנד",
          "AZ": "אזרבייג׳ן",
          "BA": "בוסניה והרצגובינה",
          "BB": "ברבדוס",
          "BD": "בנגלדש",
          "BE": "בלגיה",
          "BF": "בורקינה פאסו",
          "BG": "בולגריה",
          "BH": "בחריין",
          "BI": "בורונדי",
          "BJ": "בנין",
          "BL": "סנט ברתולומיאו",
          "BM": "ברמודה",
          "BN": "ברוניי",
          "BO": "בוליביה",
          "BQ": "האיים הקריביים ההולנדיים",
          "BR": "ברזיל",
          "BS": "איי בהאמה",
          "BT": "בהוטן",
          "BV": "האי בובה",
          "BW": "בוצוואנה",
          "BY": "בלארוס",
          "BZ": "בליז",
          "CA": "קנדה",
          "CC": "איי קוקוס (קילינג)",
          "CD": "קונגו - קינשאסה",
          "CF": "הרפובליקה המרכז-אפריקאית",
          "CG": "קונגו - ברזאויל",
          "CH": "שווייץ",
          "CI": "חוף השנהב",
          "CK": "איי קוק",
          "CL": "צ׳ילה",
          "CM": "קמרון",
          "CN": "סין",
          "CO": "קולומביה",
          "CP": "האי קליפרטון",
          "CR": "קוסטה ריקה",
          "CU": "קובה",
          "CV": "כף ורדה",
          "CW": "קוראסאו",
          "CX": "אי חג המולד",
          "CY": "קפריסין",
          "CZ": "צ׳כיה",
          "DE": "גרמניה",
          "DG": "דייגו גרסיה",
          "DJ": "ג׳יבוטי",
          "DK": "דנמרק",
          "DM": "דומיניקה",
          "DO": "הרפובליקה הדומיניקנית",
          "DZ": "אלג׳יריה",
          "EA": "סאוטה ומלייה",
          "EC": "אקוודור",
          "EE": "אסטוניה",
          "EG": "מצרים",
          "EH": "סהרה המערבית",
          "ER": "אריתריאה",
          "ES": "ספרד",
          "ET": "אתיופיה",
          "EU": "האיחוד האירופי",
          "EZ": "גוש האירו",
          "FI": "פינלנד",
          "FJ": "פיג׳י",
          "FK": "איי פוקלנד",
          "FM": "מיקרונזיה",
          "FO": "איי פארו",
          "FR": "צרפת",
          "GA": "גבון",
          "GB": "בריטניה",
          "GD": "גרנדה",
          "GE": "גאורגיה",
          "GF": "גיאנה הצרפתית",
          "GG": "גרנזי",
          "GH": "גאנה",
          "GI": "גיברלטר",
          "GL": "גרינלנד",
          "GM": "גמביה",
          "GN": "גינאה",
          "GP": "גוואדלופ",
          "GQ": "גינאה המשוונית",
          "GR": "יוון",
          "GS": "ג׳ורג׳יה הדרומית ואיי סנדוויץ׳ הדרומיים",
          "GT": "גואטמלה",
          "GU": "גואם",
          "GW": "גינאה-ביסאו",
          "GY": "גיאנה",
          "HK": "הונג קונג (אזור מנהלי מיוחד של סין)",
          "HM": "איי הרד ומקדונלד",
          "HN": "הונדורס",
          "HR": "קרואטיה",
          "HT": "האיטי",
          "HU": "הונגריה",
          "IC": "האיים הקנריים",
          "ID": "אינדונזיה",
          "IE": "אירלנד",
          "IL": "ישראל",
          "IM": "האי מאן",
          "IN": "הודו",
          "IO": "הטריטוריה הבריטית באוקיינוס ההודי",
          "IQ": "עיראק",
          "IR": "איראן",
          "IS": "איסלנד",
          "IT": "איטליה",
          "JE": "ג׳רזי",
          "JM": "ג׳מייקה",
          "JO": "ירדן",
          "JP": "יפן",
          "KE": "קניה",
          "KG": "קירגיזסטן",
          "KH": "קמבודיה",
          "KI": "קיריבאטי",
          "KM": "קומורו",
          "KN": "סנט קיטס ונוויס",
          "KP": "קוריאה הצפונית",
          "KR": "קוריאה הדרומית",
          "KW": "כווית",
          "KY": "איי קיימן",
          "KZ": "קזחסטן",
          "LA": "לאוס",
          "LB": "לבנון",
          "LC": "סנט לוסיה",
          "LI": "ליכטנשטיין",
          "LK": "סרי לנקה",
          "LR": "ליבריה",
          "LS": "לסוטו",
          "LT": "ליטא",
          "LU": "לוקסמבורג",
          "LV": "לטביה",
          "LY": "לוב",
          "MA": "מרוקו",
          "MC": "מונקו",
          "MD": "מולדובה",
          "ME": "מונטנגרו",
          "MF": "סן מרטן",
          "MG": "מדגסקר",
          "MH": "איי מרשל",
          "MK": "מקדוניה הצפונית",
          "ML": "מאלי",
          "MM": "מיאנמר (בורמה)",
          "MN": "מונגוליה",
          "MO": "מקאו (אזור מנהלי מיוחד של סין)",
          "MP": "איי מריאנה הצפוניים",
          "MQ": "מרטיניק",
          "MR": "מאוריטניה",
          "MS": "מונסראט",
          "MT": "מלטה",
          "MU": "מאוריציוס",
          "MV": "האיים המלדיביים",
          "MW": "מלאווי",
          "MX": "מקסיקו",
          "MY": "מלזיה",
          "MZ": "מוזמביק",
          "NA": "נמיביה",
          "NC": "קלדוניה החדשה",
          "NE": "ניז׳ר",
          "NF": "האי נורפוק",
          "NG": "ניגריה",
          "NI": "ניקרגואה",
          "NL": "הולנד",
          "NO": "נורווגיה",
          "NP": "נפאל",
          "NR": "נאורו",
          "NU": "ניווה",
          "NZ": "ניו זילנד",
          "OM": "עומאן",
          "PA": "פנמה",
          "PE": "פרו",
          "PF": "פולינזיה הצרפתית",
          "PG": "פפואה גינאה החדשה",
          "PH": "הפיליפינים",
          "PK": "פקיסטן",
          "PL": "פולין",
          "PM": "סנט פייר ומיקלון",
          "PN": "איי פיטקרן",
          "PR": "פוארטו ריקו",
          "PS": "השטחים הפלסטיניים",
          "PT": "פורטוגל",
          "PW": "פלאו",
          "PY": "פרגוואי",
          "QA": "קטאר",
          "QO": "טריטוריות באוקיאניה",
          "RE": "ראוניון",
          "RO": "רומניה",
          "RS": "סרביה",
          "RU": "רוסיה",
          "RW": "רואנדה",
          "SA": "ערב הסעודית",
          "SB": "איי שלמה",
          "SC": "איי סיישל",
          "SD": "סודן",
          "SE": "שוודיה",
          "SG": "סינגפור",
          "SH": "סנט הלנה",
          "SI": "סלובניה",
          "SJ": "סבאלברד ויאן מאיין",
          "SK": "סלובקיה",
          "SL": "סיירה לאונה",
          "SM": "סן מרינו",
          "SN": "סנגל",
          "SO": "סומליה",
          "SR": "סורינאם",
          "SS": "דרום סודן",
          "ST": "סאו טומה ופרינסיפה",
          "SV": "אל סלבדור",
          "SX": "סנט מארטן",
          "SY": "סוריה",
          "SZ": "אסוואטיני",
          "TA": "טריסטן דה קונה",
          "TC": "איי טרקס וקייקוס",
          "TD": "צ׳אד",
          "TF": "הטריטוריות הדרומיות של צרפת",
          "TG": "טוגו",
          "TH": "תאילנד",
          "TJ": "טג׳יקיסטן",
          "TK": "טוקלאו",
          "TL": "טימור-לסטה",
          "TM": "טורקמניסטן",
          "TN": "תוניסיה",
          "TO": "טונגה",
          "TR": "טורקיה",
          "TT": "טרינידד וטובגו",
          "TV": "טובאלו",
          "TW": "טייוואן",
          "TZ": "טנזניה",
          "UA": "אוקראינה",
          "UG": "אוגנדה",
          "UM": "האיים המרוחקים הקטנים של ארה״ב",
          "UN": "האומות המאוחדות",
          "US": "ארצות הברית",
          "UY": "אורוגוואי",
          "UZ": "אוזבקיסטן",
          "VA": "הוותיקן",
          "VC": "סנט וינסנט והגרנדינים",
          "VE": "ונצואלה",
          "VG": "איי הבתולה הבריטיים",
          "VI": "איי הבתולה של ארצות הברית",
          "VN": "וייטנאם",
          "VU": "ונואטו",
          "WF": "איי ווליס ופוטונה",
          "WS": "סמואה",
          "XA": "מבטאים חלקיים",
          "XB": "דו-כיווני חלקי",
          "XK": "קוסובו",
          "YE": "תימן",
          "YT": "מאיוט",
          "ZA": "דרום אפריקה",
          "ZM": "זמביה",
          "ZW": "זימבבואה",
          "ZZ": "אזור לא ידוע"
        },
        "narrow": {
        },
        "short": {
          "GB": "בריטניה",
          "HK": "הונג קונג",
          "MO": "מקאו",
          "PS": "פלסטין",
          "US": "ארה״ב"
        }
      },
      "script": {
        "long": {
          "Adlm": "אדלם",
          "Aghb": "אלבני קווקזי",
          "Ahom": "אהום",
          "Arab": "ערבי",
          "Aran": "נסתעליק",
          "Armi": "ארמית רשמית",
          "Armn": "ארמני",
          "Avst": "אווסטן",
          "Bali": "באלינזי",
          "Bamu": "במום",
          "Bass": "באסה ואה",
          "Batk": "בטק",
          "Beng": "בנגלי",
          "Bhks": "בהיקסוקי",
          "Bopo": "בופומופו",
          "Brah": "ברהמי",
          "Brai": "ברייל",
          "Bugi": "בוגינזי",
          "Buhd": "בוהיד",
          "Cakm": "צ׳אקמה",
          "Cans": "סילביקה קדומה מאוחדת של קנדה",
          "Cari": "קריאן",
          "Cham": "צ׳אם",
          "Cher": "צ׳ירוקי",
          "Chrs": "כורזמיאן",
          "Copt": "קופטי",
          "Cpmn": "ציפרו-מינואן",
          "Cprt": "קפריסאי",
          "Cyrl": "קירילי",
          "Cyrs": "קירילי סלאבוני כנסייתי עתיק",
          "Deva": "דוואנגרי",
          "Diak": "אותיות אי",
          "Dogr": "דוגרה",
          "Dsrt": "דסרט",
          "Dupl": "קיצור של דובילרן",
          "Egyp": "כתב חרטומים",
          "Elba": "אלבסאן",
          "Elym": "אלימי",
          "Ethi": "אתיופי",
          "Geor": "גאורגי",
          "Glag": "גלגוליטי",
          "Gong": "ג’ונג’לה מבולבל",
          "Gonm": "מסארם גונדי",
          "Goth": "גותי",
          "Gran": "גרנתה",
          "Grek": "יווני",
          "Gujr": "גוג׳רטי",
          "Guru": "גורמוקי",
          "Hanb": "האן עם בופומופו",
          "Hang": "האנגול",
          "Hani": "האן",
          "Hano": "האנונו",
          "Hans": "פשוט",
          "Hant": "מסורתי",
          "Hatr": "חטרן",
          "Hebr": "עברי",
          "Hira": "הירגאנה",
          "Hluw": "הירוגליפים האנטוליים",
          "Hmng": "פאהח המונג",
          "Hmnp": "ניאקנג פואצ׳ה המונג",
          "Hrkt": "הברתי יפני",
          "Hung": "הונגרי עתיק",
          "Inds": "אינדוס",
          "Ital": "איטלקי עתיק",
          "Jamo": "ג׳אמו",
          "Java": "ג׳אוונזי",
          "Jpan": "יפני",
          "Kali": "קאי לי",
          "Kana": "קטקאנה",
          "Khar": "חרושתי",
          "Khmr": "חמרי",
          "Khoj": "חוג׳קי",
          "Kits": "כתב קטן של חיטן",
          "Knda": "קאנאדה",
          "Kore": "קוריאני",
          "Kthi": "קאיתי",
          "Lana": "לאנה",
          "Laoo": "לאי",
          "Latg": "לטיני גאלי",
          "Latn": "לטיני",
          "Lepc": "לפחה",
          "Limb": "לימבו",
          "Lina": "ליניארי א",
          "Linb": "ליניארי ב",
          "Lisu": "פרייזר",
          "Lyci": "ליקי",
          "Lydi": "לידי",
          "Mahj": "מהג׳אני",
          "Maka": "מקאסאר",
          "Mand": "מנדאית",
          "Mani": "מניצ׳י",
          "Marc": "מרצ׳ן",
          "Maya": "מאיה",
          "Medf": "מדפיידרין",
          "Mend": "מנדה",
          "Merc": "קורסית מרואיטית",
          "Mero": "מרואיטית",
          "Mlym": "מליאלאם",
          "Modi": "מודי",
          "Mong": "מונגולי",
          "Mroo": "מרו",
          "Mtei": "מאיטי מאייק",
          "Mult": "מולטיני",
          "Mymr": "מיאנמר",
          "Nand": "ננדינאגרי",
          "Narb": "ערב צפון עתיק",
          "Nbat": "נבטי",
          "Newa": "נווארית",
          "Nkoo": "נ׳קו",
          "Nshu": "נושו",
          "Ogam": "אוהם",
          "Olck": "אול צ׳יקי",
          "Orkh": "אורחון",
          "Orya": "אודייה",
          "Osge": "אוסייג׳",
          "Osma": "אוסמניה",
          "Ougr": "אוגורית ישנה",
          "Palm": "פלמירן",
          "Pauc": "פאו צ׳ין האו",
          "Perm": "פרמית ישנה",
          "Phag": "פאגס",
          "Phli": "פהלווי כתובתי",
          "Phlp": "מזמור פהלווי",
          "Phnx": "פיניקי",
          "Plrd": "פולארד פונטי",
          "Prti": "פרטית כתובה",
          "Qaag": "זאוגיי",
          "Rjng": "רג׳אנג",
          "Rohg": "חניפי",
          "Runr": "רוני",
          "Samr": "שומרונית",
          "Sarb": "דרום ערבית",
          "Saur": "סאוראשטרה",
          "Sgnw": "חתימה",
          "Shaw": "שבית",
          "Shrd": "שרדה",
          "Sidd": "סידהם",
          "Sind": "חודוואדי",
          "Sinh": "סינהלה",
          "Sogd": "סוגדית",
          "Sogo": "סוגדית עתיקה",
          "Sora": "סורה סומפנג",
          "Soyo": "סויומבו",
          "Sund": "סונדאנית",
          "Sylo": "סילוטי נגרי",
          "Syrc": "סורי",
          "Syrj": "סורי מערבי",
          "Syrn": "סורי מזרחי",
          "Tagb": "טגבנווה",
          "Takr": "טאקרי",
          "Tale": "טאי לה",
          "Talu": "טאי ליו חדש",
          "Taml": "טמיל",
          "Tang": "טאנגוט",
          "Tavt": "טאי וייט",
          "Telu": "טלוגו",
          "Tfng": "טיפינה",
          "Tglg": "טגלוג",
          "Thaa": "תאנה",
          "Thai": "תאי",
          "Tibt": "טיבטי",
          "Tirh": "תרותא",
          "Tnsa": "טנגסה",
          "Toto": "טוטו",
          "Ugar": "אוגריתי",
          "Vaii": "ואי",
          "Vith": "ויטוקוקי",
          "Wara": "ווראנג סיטי",
          "Wcho": "וונצ׳ו",
          "Xpeo": "פרסי עתיק",
          "Xsux": "כתב היתדות השומרי-אכדי",
          "Yezi": "יעזי",
          "Yiii": "יי",
          "Zanb": "כיכר זנבזר",
          "Zinh": "מורש",
          "Zmth": "סימון מתמטי",
          "Zsye": "אמוג׳י",
          "Zsym": "סמלים",
          "Zxxx": "לא כתוב",
          "Zyyy": "רגיל",
          "Zzzz": "כתב שאינו ידוע"
        },
        "narrow": {
        },
        "short": {
        }
      }
    }
  },
  "locale": "he"
})
}