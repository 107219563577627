/* @generated */
// prettier-ignore
if (Intl.DisplayNames && typeof Intl.DisplayNames.__addLocaleData === 'function') {
  Intl.DisplayNames.__addLocaleData({
  "data": {
    "patterns": {
      "locale": "{0} ({1})"
    },
    "types": {
      "calendar": {
        "long": {
          "buddhist": "буддийский календарь",
          "chinese": "китайский календарь",
          "coptic": "Коптский календарь",
          "dangi": "календарь данги",
          "ethiopic": "эфиопский календарь",
          "ethiopic-amete-alem": "Эфиопский календарь \\\"Амете Алем\\\"",
          "gregorian": "григорианский календарь",
          "hebrew": "еврейский календарь",
          "indian": "Национальный календарь Индии",
          "islamic": "исламский календарь",
          "islamic-civil": "Исламский гражданский календарь",
          "islamic-rgsa": "исламский календарь (Саудовская Аравия)",
          "islamic-tbla": "исламский календарь (табличный, астрономическая эпоха)",
          "islamic-umalqura": "исламский календарь (Умм аль-Кура)",
          "iso8601": "календарь ISO-8601",
          "japanese": "японский календарь",
          "persian": "персидский календарь",
          "roc": "календарь Миньго"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "currency": {
        "long": {
          "ADP": "Андоррская песета",
          "AED": "дирхам ОАЭ",
          "AFA": "Афгани (1927–2002)",
          "AFN": "афгани",
          "ALK": "ALK",
          "ALL": "албанский лек",
          "AMD": "армянский драм",
          "ANG": "нидерландский антильский гульден",
          "AOA": "ангольская кванза",
          "AOK": "Ангольская кванза (1977–1990)",
          "AON": "Ангольская новая кванза (1990–2000)",
          "AOR": "Ангольская кванза реюстадо (1995–1999)",
          "ARA": "Аргентинский аустрал",
          "ARL": "ARL",
          "ARM": "ARM",
          "ARP": "Аргентинское песо (1983–1985)",
          "ARS": "аргентинский песо",
          "ATS": "Австрийский шиллинг",
          "AUD": "австралийский доллар",
          "AWG": "арубанский флорин",
          "AZM": "Старый азербайджанский манат",
          "AZN": "азербайджанский манат",
          "BAD": "Динар Боснии и Герцеговины",
          "BAM": "конвертируемая марка Боснии и Герцеговины",
          "BAN": "BAN",
          "BBD": "барбадосский доллар",
          "BDT": "бангладешская така",
          "BEC": "Бельгийский франк (конвертируемый)",
          "BEF": "Бельгийский франк",
          "BEL": "Бельгийский франк (финансовый)",
          "BGL": "Лев",
          "BGM": "BGM",
          "BGN": "болгарский лев",
          "BGO": "BGO",
          "BHD": "бахрейнский динар",
          "BIF": "бурундийский франк",
          "BMD": "бермудский доллар",
          "BND": "брунейский доллар",
          "BOB": "боливийский боливиано",
          "BOL": "BOL",
          "BOP": "Боливийское песо",
          "BOV": "Боливийский мвдол",
          "BRB": "Бразильский новый крузейро (1967–1986)",
          "BRC": "Бразильское крузадо",
          "BRE": "Бразильский крузейро (1990–1993)",
          "BRL": "бразильский реал",
          "BRN": "Бразильское новое крузадо",
          "BRR": "Бразильский крузейро",
          "BRZ": "BRZ",
          "BSD": "багамский доллар",
          "BTN": "бутанский нгултрум",
          "BUK": "Джа",
          "BWP": "ботсванская пула",
          "BYB": "Белорусский рубль (1994–1999)",
          "BYN": "белорусский рубль",
          "BYR": "Белорусский рубль (2000–2016)",
          "BZD": "белизский доллар",
          "CAD": "канадский доллар",
          "CDF": "конголезский франк",
          "CHE": "WIR евро",
          "CHF": "швейцарский франк",
          "CHW": "WIR франк",
          "CLE": "CLE",
          "CLF": "Условная расчетная единица Чили",
          "CLP": "чилийский песо",
          "CNH": "китайский офшорный юань",
          "CNX": "CNX",
          "CNY": "китайский юань",
          "COP": "колумбийский песо",
          "COU": "Единица реальной стоимости Колумбии",
          "CRC": "костариканский колон",
          "CSD": "Старый Сербский динар",
          "CSK": "Чехословацкая твердая крона",
          "CUC": "кубинский конвертируемый песо",
          "CUP": "кубинский песо",
          "CVE": "эскудо Кабо-Верде",
          "CYP": "Кипрский фунт",
          "CZK": "чешская крона",
          "DDM": "Восточногерманская марка",
          "DEM": "Немецкая марка",
          "DJF": "франк Джибути",
          "DKK": "датская крона",
          "DOP": "доминиканский песо",
          "DZD": "алжирский динар",
          "ECS": "Эквадорский сукре",
          "ECV": "Постоянная единица стоимости Эквадора",
          "EEK": "Эстонская крона",
          "EGP": "египетский фунт",
          "ERN": "эритрейская накфа",
          "ESA": "Испанская песета (А)",
          "ESB": "Испанская песета (конвертируемая)",
          "ESP": "Испанская песета",
          "ETB": "эфиопский быр",
          "EUR": "евро",
          "FIM": "Финская марка",
          "FJD": "доллар Фиджи",
          "FKP": "фунт Фолклендских островов",
          "FRF": "Французский франк",
          "GBP": "британский фунт стерлингов",
          "GEK": "Грузинский купон",
          "GEL": "грузинский лари",
          "GHC": "Ганский седи (1979–2007)",
          "GHS": "ганский седи",
          "GIP": "гибралтарский фунт",
          "GMD": "гамбийский даласи",
          "GNF": "гвинейский франк",
          "GNS": "Гвинейская сили",
          "GQE": "Эквеле экваториальной Гвинеи",
          "GRD": "Греческая драхма",
          "GTQ": "гватемальский кетсаль",
          "GWE": "Эскудо Португальской Гвинеи",
          "GWP": "Песо Гвинеи-Бисау",
          "GYD": "гайанский доллар",
          "HKD": "гонконгский доллар",
          "HNL": "гондурасская лемпира",
          "HRD": "Хорватский динар",
          "HRK": "хорватская куна",
          "HTG": "гаитянский гурд",
          "HUF": "венгерский форинт",
          "IDR": "индонезийская рупия",
          "IEP": "Ирландский фунт",
          "ILP": "Израильский фунт",
          "ILR": "ILR",
          "ILS": "новый израильский шекель",
          "INR": "индийская рупия",
          "IQD": "иракский динар",
          "IRR": "иранский риал",
          "ISJ": "ISJ",
          "ISK": "исландская крона",
          "ITL": "Итальянская лира",
          "JMD": "ямайский доллар",
          "JOD": "иорданский динар",
          "JPY": "японская иена",
          "KES": "кенийский шиллинг",
          "KGS": "киргизский сом",
          "KHR": "камбоджийский риель",
          "KMF": "коморский франк",
          "KPW": "северокорейская вона",
          "KRH": "KRH",
          "KRO": "KRO",
          "KRW": "южнокорейская вона",
          "KWD": "кувейтский динар",
          "KYD": "доллар Островов Кайман",
          "KZT": "казахский тенге",
          "LAK": "лаосский кип",
          "LBP": "ливанский фунт",
          "LKR": "шри-ланкийская рупия",
          "LRD": "либерийский доллар",
          "LSL": "лоти",
          "LTL": "Литовский лит",
          "LTT": "Литовский талон",
          "LUC": "Конвертируемый франк Люксембурга",
          "LUF": "Люксембургский франк",
          "LUL": "Финансовый франк Люксембурга",
          "LVL": "Латвийский лат",
          "LVR": "Латвийский рубль",
          "LYD": "ливийский динар",
          "MAD": "марокканский дирхам",
          "MAF": "Марокканский франк",
          "MCF": "MCF",
          "MDC": "MDC",
          "MDL": "молдавский лей",
          "MGA": "малагасийский ариари",
          "MGF": "Малагасийский франк",
          "MKD": "македонский денар",
          "MKN": "MKN",
          "MLF": "Малийский франк",
          "MMK": "мьянманский кьят",
          "MNT": "монгольский тугрик",
          "MOP": "патака Макао",
          "MRO": "мавританская угия (1973–2017)",
          "MRU": "мавританская угия",
          "MTL": "Мальтийская лира",
          "MTP": "Мальтийский фунт",
          "MUR": "маврикийская рупия",
          "MVP": "MVP",
          "MVR": "мальдивская руфия",
          "MWK": "малавийская квача",
          "MXN": "мексиканский песо",
          "MXP": "Мексиканское серебряное песо (1861–1992)",
          "MXV": "Мексиканская пересчетная единица (UDI)",
          "MYR": "малайзийский ринггит",
          "MZE": "Мозамбикское эскудо",
          "MZM": "Старый мозамбикский метикал",
          "MZN": "мозамбикский метикал",
          "NAD": "доллар Намибии",
          "NGN": "нигерийская найра",
          "NIC": "Никарагуанская кордоба (1988–1991)",
          "NIO": "никарагуанская кордоба",
          "NLG": "Нидерландский гульден",
          "NOK": "норвежская крона",
          "NPR": "непальская рупия",
          "NZD": "новозеландский доллар",
          "OMR": "оманский риал",
          "PAB": "панамский бальбоа",
          "PEI": "Перуанское инти",
          "PEN": "перуанский соль",
          "PES": "Перуанский соль (1863–1965)",
          "PGK": "кина Папуа – Новой Гвинеи",
          "PHP": "филиппинский песо",
          "PKR": "пакистанская рупия",
          "PLN": "польский злотый",
          "PLZ": "Злотый",
          "PTE": "Португальское эскудо",
          "PYG": "парагвайский гуарани",
          "QAR": "катарский риал",
          "RHD": "Родезийский доллар",
          "ROL": "Старый Румынский лей",
          "RON": "румынский лей",
          "RSD": "сербский динар",
          "RUB": "российский рубль",
          "RUR": "Российский рубль (1991–1998)",
          "RWF": "франк Руанды",
          "SAR": "саудовский риял",
          "SBD": "доллар Соломоновых Островов",
          "SCR": "сейшельская рупия",
          "SDD": "Суданский динар",
          "SDG": "суданский фунт",
          "SDP": "Старый суданский фунт",
          "SEK": "шведская крона",
          "SGD": "сингапурский доллар",
          "SHP": "фунт острова Святой Елены",
          "SIT": "Словенский толар",
          "SKK": "Словацкая крона",
          "SLL": "леоне",
          "SOS": "сомалийский шиллинг",
          "SRD": "суринамский доллар",
          "SRG": "Суринамский гульден",
          "SSP": "южносуданский фунт",
          "STD": "добра Сан-Томе и Принсипи (1977–2017)",
          "STN": "добра Сан-Томе и Принсипи",
          "SUR": "Рубль СССР",
          "SVC": "Сальвадорский колон",
          "SYP": "сирийский фунт",
          "SZL": "свазилендский лилангени",
          "THB": "таиландский бат",
          "TJR": "Таджикский рубль",
          "TJS": "таджикский сомони",
          "TMM": "Туркменский манат",
          "TMT": "новый туркменский манат",
          "TND": "тунисский динар",
          "TOP": "тонганская паанга",
          "TPE": "Тиморское эскудо",
          "TRL": "Турецкая лира (1922–2005)",
          "TRY": "турецкая лира",
          "TTD": "доллар Тринидада и Тобаго",
          "TWD": "новый тайваньский доллар",
          "TZS": "танзанийский шиллинг",
          "UAH": "украинская гривна",
          "UAK": "Карбованец (украинский)",
          "UGS": "Старый угандийский шиллинг",
          "UGX": "угандийский шиллинг",
          "USD": "доллар США",
          "USN": "Доллар США следующего дня",
          "USS": "Доллар США текущего дня",
          "UYI": "Уругвайский песо (индекс инфляции)",
          "UYP": "Уругвайское старое песо (1975–1993)",
          "UYU": "уругвайский песо",
          "UYW": "UYW",
          "UZS": "узбекский сум",
          "VEB": "Венесуэльский боливар (1871–2008)",
          "VEF": "венесуэльский боливар (2008–2018)",
          "VES": "венесуэльский боливар",
          "VND": "вьетнамский донг",
          "VNN": "VNN",
          "VUV": "вату Вануату",
          "WST": "самоанская тала",
          "XAF": "франк КФА BEAC",
          "XAG": "Серебро",
          "XAU": "Золото",
          "XBA": "Европейская составная единица",
          "XBB": "Европейская денежная единица",
          "XBC": "расчетная единица европейского валютного соглашения (XBC)",
          "XBD": "расчетная единица европейского валютного соглашения (XBD)",
          "XCD": "восточно-карибский доллар",
          "XDR": "СДР (специальные права заимствования)",
          "XEU": "ЭКЮ (единица европейской валюты)",
          "XFO": "Французский золотой франк",
          "XFU": "Французский UIC-франк",
          "XOF": "франк КФА ВСЕАО",
          "XPD": "Палладий",
          "XPF": "французский тихоокеанский франк",
          "XPT": "Платина",
          "XRE": "единица RINET-фондов",
          "XSU": "XSU",
          "XTS": "тестовый валютный код",
          "XUA": "XUA",
          "XXX": "неизвестная валюта",
          "YDD": "Йеменский динар",
          "YER": "йеменский риал",
          "YUD": "Югославский твердый динар",
          "YUM": "Югославский новый динар",
          "YUN": "Югославский динар",
          "YUR": "YUR",
          "ZAL": "Южноафриканский рэнд (финансовый)",
          "ZAR": "южноафриканский рэнд",
          "ZMK": "Квача (замбийская) (1968–2012)",
          "ZMW": "замбийская квача",
          "ZRN": "Новый заир",
          "ZRZ": "Заир",
          "ZWD": "Доллар Зимбабве",
          "ZWL": "Доллар Зимбабве (2009)",
          "ZWR": "ZWR"
        },
        "narrow": {
        },
        "short": {
        }
      },
      "dateTimeField": {
        "long": {
          "day": "день",
          "dayOfYear": "день года",
          "dayperiod": "AM/PM",
          "era": "эра",
          "hour": "час",
          "minute": "минута",
          "month": "месяц",
          "quarter": "квартал",
          "second": "секунда",
          "timeZoneName": "часовой пояс",
          "weekOfMonth": "неделя месяца",
          "weekOfYear": "неделя",
          "weekday": "день недели",
          "weekdayOfMonth": "день недели в месяце",
          "year": "год"
        },
        "narrow": {
          "day": "дн.",
          "dayOfYear": "дн. года",
          "dayperiod": "AM/PM",
          "era": "эра",
          "hour": "ч",
          "minute": "мин",
          "month": "мес.",
          "quarter": "кв.",
          "second": "с",
          "timeZoneName": "час. пояс",
          "weekOfMonth": "нед. мес.",
          "weekOfYear": "нед.",
          "weekday": "дн. нед.",
          "weekdayOfMonth": "дн. нед. в мес.",
          "year": "г."
        },
        "short": {
          "day": "дн.",
          "dayOfYear": "дн. года",
          "dayperiod": "AM/PM",
          "era": "эра",
          "hour": "ч",
          "minute": "мин.",
          "month": "мес.",
          "quarter": "кв.",
          "second": "сек.",
          "timeZoneName": "час. пояс",
          "weekOfMonth": "нед. месяца",
          "weekOfYear": "нед.",
          "weekday": "дн. недели",
          "weekdayOfMonth": "дн. нед. в месяце",
          "year": "г."
        }
      },
      "language": {
        "dialect": {
          "long": {
            "aa": "афарский",
            "ab": "абхазский",
            "ace": "ачехский",
            "ach": "ачоли",
            "ada": "адангме",
            "ady": "адыгейский",
            "ae": "авестийский",
            "af": "африкаанс",
            "afh": "африхили",
            "agq": "агем",
            "ain": "айнский",
            "ak": "акан",
            "akk": "аккадский",
            "ale": "алеутский",
            "alt": "южноалтайский",
            "am": "амхарский",
            "an": "арагонский",
            "ang": "староанглийский",
            "anp": "ангика",
            "ar": "арабский",
            "ar-001": "арабский литературный",
            "arc": "арамейский",
            "arn": "мапуче",
            "arp": "арапахо",
            "ars": "недждийский арабский",
            "arw": "аравакский",
            "as": "ассамский",
            "asa": "асу",
            "ast": "астурийский",
            "av": "аварский",
            "awa": "авадхи",
            "ay": "аймара",
            "az": "азербайджанский",
            "ba": "башкирский",
            "bal": "белуджский",
            "ban": "балийский",
            "bas": "баса",
            "bax": "бамум",
            "bbj": "гомала",
            "be": "белорусский",
            "bej": "беджа",
            "bem": "бемба",
            "bez": "бена",
            "bfd": "бафут",
            "bg": "болгарский",
            "bgn": "западный белуджский",
            "bho": "бходжпури",
            "bi": "бислама",
            "bik": "бикольский",
            "bin": "бини",
            "bkm": "ком",
            "bla": "сиксика",
            "bm": "бамбара",
            "bn": "бенгальский",
            "bo": "тибетский",
            "br": "бретонский",
            "bra": "брауи",
            "brx": "бодо",
            "bs": "боснийский",
            "bss": "акоосе",
            "bua": "бурятский",
            "bug": "бугийский",
            "bum": "булу",
            "byn": "билин",
            "byv": "медумба",
            "ca": "каталанский",
            "cad": "каддо",
            "car": "кариб",
            "cay": "кайюга",
            "cch": "атсам",
            "ccp": "чакма",
            "ce": "чеченский",
            "ceb": "себуано",
            "cgg": "кига",
            "ch": "чаморро",
            "chb": "чибча",
            "chg": "чагатайский",
            "chk": "чукотский",
            "chm": "марийский",
            "chn": "чинук жаргон",
            "cho": "чоктавский",
            "chp": "чипевьян",
            "chr": "чероки",
            "chy": "шайенский",
            "ckb": "центральнокурдский",
            "co": "корсиканский",
            "cop": "коптский",
            "cr": "кри",
            "crh": "крымско-татарский",
            "crs": "сейшельский креольский",
            "cs": "чешский",
            "csb": "кашубский",
            "cu": "церковнославянский",
            "cv": "чувашский",
            "cy": "валлийский",
            "da": "датский",
            "dak": "дакота",
            "dar": "даргинский",
            "dav": "таита",
            "de": "немецкий",
            "de-AT": "австрийский немецкий",
            "de-CH": "литературный швейцарский немецкий",
            "del": "делаварский",
            "den": "слейви",
            "dgr": "догриб",
            "din": "динка",
            "dje": "джерма",
            "doi": "догри",
            "dsb": "нижнелужицкий",
            "dua": "дуала",
            "dum": "средненидерландский",
            "dv": "мальдивский",
            "dyo": "диола-фоньи",
            "dyu": "диула",
            "dz": "дзонг-кэ",
            "dzg": "даза",
            "ebu": "эмбу",
            "ee": "эве",
            "efi": "эфик",
            "egy": "древнеегипетский",
            "eka": "экаджук",
            "el": "греческий",
            "elx": "эламский",
            "en": "английский",
            "en-AU": "австралийский английский",
            "en-CA": "канадский английский",
            "en-GB": "британский английский",
            "en-US": "американский английский",
            "enm": "среднеанглийский",
            "eo": "эсперанто",
            "es": "испанский",
            "es-419": "латиноамериканский испанский",
            "es-ES": "европейский испанский",
            "es-MX": "мексиканский испанский",
            "et": "эстонский",
            "eu": "баскский",
            "ewo": "эвондо",
            "fa": "персидский",
            "fa-AF": "дари",
            "fan": "фанг",
            "fat": "фанти",
            "ff": "фулах",
            "fi": "финский",
            "fil": "филиппинский",
            "fj": "фиджи",
            "fo": "фарерский",
            "fon": "фон",
            "fr": "французский",
            "fr-CA": "канадский французский",
            "fr-CH": "швейцарский французский",
            "frc": "каджунский французский",
            "frm": "среднефранцузский",
            "fro": "старофранцузский",
            "frr": "северный фризский",
            "frs": "восточный фризский",
            "fur": "фриульский",
            "fy": "западнофризский",
            "ga": "ирландский",
            "gaa": "га",
            "gag": "гагаузский",
            "gan": "гань",
            "gay": "гайо",
            "gba": "гбая",
            "gd": "гэльский",
            "gez": "геэз",
            "gil": "гилбертский",
            "gl": "галисийский",
            "gmh": "средневерхненемецкий",
            "gn": "гуарани",
            "goh": "древневерхненемецкий",
            "gon": "гонди",
            "gor": "горонтало",
            "got": "готский",
            "grb": "гребо",
            "grc": "древнегреческий",
            "gsw": "швейцарский немецкий",
            "gu": "гуджарати",
            "guz": "гусии",
            "gv": "мэнский",
            "gwi": "гвичин",
            "ha": "хауса",
            "hai": "хайда",
            "hak": "хакка",
            "haw": "гавайский",
            "he": "иврит",
            "hi": "хинди",
            "hil": "хилигайнон",
            "hit": "хеттский",
            "hmn": "хмонг",
            "ho": "хиримоту",
            "hr": "хорватский",
            "hsb": "верхнелужицкий",
            "hsn": "сян",
            "ht": "гаитянский",
            "hu": "венгерский",
            "hup": "хупа",
            "hy": "армянский",
            "hz": "гереро",
            "ia": "интерлингва",
            "iba": "ибанский",
            "ibb": "ибибио",
            "id": "индонезийский",
            "ie": "интерлингве",
            "ig": "игбо",
            "ii": "носу",
            "ik": "инупиак",
            "ilo": "илоко",
            "inh": "ингушский",
            "io": "идо",
            "is": "исландский",
            "it": "итальянский",
            "iu": "инуктитут",
            "ja": "японский",
            "jbo": "ложбан",
            "jgo": "нгомба",
            "jmc": "мачаме",
            "jpr": "еврейско-персидский",
            "jrb": "еврейско-арабский",
            "jv": "яванский",
            "ka": "грузинский",
            "kaa": "каракалпакский",
            "kab": "кабильский",
            "kac": "качинский",
            "kaj": "каджи",
            "kam": "камба",
            "kaw": "кави",
            "kbd": "кабардинский",
            "kbl": "канембу",
            "kcg": "тьяп",
            "kde": "маконде",
            "kea": "кабувердьяну",
            "kfo": "коро",
            "kg": "конго",
            "kgp": "kgp",
            "kha": "кхаси",
            "kho": "хотанский",
            "khq": "койра чиини",
            "ki": "кикуйю",
            "kj": "кунама",
            "kk": "казахский",
            "kkj": "како",
            "kl": "гренландский",
            "kln": "календжин",
            "km": "кхмерский",
            "kmb": "кимбунду",
            "kn": "каннада",
            "ko": "корейский",
            "koi": "коми-пермяцкий",
            "kok": "конкани",
            "kos": "косраенский",
            "kpe": "кпелле",
            "kr": "канури",
            "krc": "карачаево-балкарский",
            "krl": "карельский",
            "kru": "курух",
            "ks": "кашмири",
            "ksb": "шамбала",
            "ksf": "бафия",
            "ksh": "кёльнский",
            "ku": "курдский",
            "kum": "кумыкский",
            "kut": "кутенаи",
            "kv": "коми",
            "kw": "корнский",
            "ky": "киргизский",
            "la": "латинский",
            "lad": "ладино",
            "lag": "ланго",
            "lah": "лахнда",
            "lam": "ламба",
            "lb": "люксембургский",
            "lez": "лезгинский",
            "lg": "ганда",
            "li": "лимбургский",
            "lij": "lij",
            "lkt": "лакота",
            "ln": "лингала",
            "lo": "лаосский",
            "lol": "монго",
            "lou": "луизианский креольский",
            "loz": "лози",
            "lrc": "севернолурский",
            "lt": "литовский",
            "lu": "луба-катанга",
            "lua": "луба-лулуа",
            "lui": "луисеньо",
            "lun": "лунда",
            "luo": "луо",
            "lus": "мизо",
            "luy": "лухья",
            "lv": "латышский",
            "mad": "мадурский",
            "maf": "мафа",
            "mag": "магахи",
            "mai": "майтхили",
            "mak": "макассарский",
            "man": "мандинго",
            "mas": "масаи",
            "mde": "маба",
            "mdf": "мокшанский",
            "mdr": "мандарский",
            "men": "менде",
            "mer": "меру",
            "mfe": "маврикийский креольский",
            "mg": "малагасийский",
            "mga": "среднеирландский",
            "mgh": "макуа-меетто",
            "mgo": "мета",
            "mh": "маршалльский",
            "mi": "маори",
            "mic": "микмак",
            "min": "минангкабау",
            "mk": "македонский",
            "ml": "малаялам",
            "mn": "монгольский",
            "mnc": "маньчжурский",
            "mni": "манипурский",
            "moh": "мохаук",
            "mos": "моси",
            "mr": "маратхи",
            "ms": "малайский",
            "mt": "мальтийский",
            "mua": "мунданг",
            "mul": "языки разных семей",
            "mus": "крик",
            "mwl": "мирандский",
            "mwr": "марвари",
            "my": "бирманский",
            "mye": "миене",
            "myv": "эрзянский",
            "mzn": "мазандеранский",
            "na": "науру",
            "nan": "миньнань",
            "nap": "неаполитанский",
            "naq": "нама",
            "nb": "норвежский букмол",
            "nd": "северный ндебеле",
            "nds": "нижненемецкий",
            "nds-NL": "нижнесаксонский",
            "ne": "непальский",
            "new": "неварский",
            "ng": "ндонга",
            "nia": "ниас",
            "niu": "ниуэ",
            "nl": "нидерландский",
            "nl-BE": "фламандский",
            "nmg": "квасио",
            "nn": "нюнорск",
            "nnh": "нгиембунд",
            "no": "норвежский",
            "nog": "ногайский",
            "non": "старонорвежский",
            "nqo": "нко",
            "nr": "южный ндебеле",
            "nso": "северный сото",
            "nus": "нуэр",
            "nv": "навахо",
            "nwc": "классический невари",
            "ny": "ньянджа",
            "nym": "ньямвези",
            "nyn": "ньянколе",
            "nyo": "ньоро",
            "nzi": "нзима",
            "oc": "окситанский",
            "oj": "оджибва",
            "om": "оромо",
            "or": "ория",
            "os": "осетинский",
            "osa": "оседжи",
            "ota": "старотурецкий",
            "pa": "панджаби",
            "pag": "пангасинан",
            "pal": "пехлевийский",
            "pam": "пампанга",
            "pap": "папьяменто",
            "pau": "палау",
            "pcm": "нигерийско-креольский",
            "peo": "староперсидский",
            "phn": "финикийский",
            "pi": "пали",
            "pl": "польский",
            "pon": "понапе",
            "prg": "прусский",
            "pro": "старопровансальский",
            "ps": "пушту",
            "pt": "португальский",
            "pt-BR": "бразильский португальский",
            "pt-PT": "европейский португальский",
            "qu": "кечуа",
            "quc": "киче",
            "raj": "раджастхани",
            "rap": "рапануйский",
            "rar": "раротонга",
            "rhg": "рохинджа",
            "rm": "романшский",
            "rn": "рунди",
            "ro": "румынский",
            "ro-MD": "молдавский",
            "rof": "ромбо",
            "rom": "цыганский",
            "ru": "русский",
            "rup": "арумынский",
            "rw": "киньяруанда",
            "rwk": "руанда",
            "sa": "санскрит",
            "sad": "сандаве",
            "sah": "саха",
            "sam": "самаритянский арамейский",
            "saq": "самбуру",
            "sas": "сасакский",
            "sat": "сантали",
            "sba": "нгамбайский",
            "sbp": "сангу",
            "sc": "сардинский",
            "scn": "сицилийский",
            "sco": "шотландский",
            "sd": "синдхи",
            "sdh": "южнокурдский",
            "se": "северносаамский",
            "see": "сенека",
            "seh": "сена",
            "sel": "селькупский",
            "ses": "койраборо сенни",
            "sg": "санго",
            "sga": "староирландский",
            "sh": "сербскохорватский",
            "shi": "ташельхит",
            "shn": "шанский",
            "shu": "чадский арабский",
            "si": "сингальский",
            "sid": "сидама",
            "sk": "словацкий",
            "sl": "словенский",
            "sm": "самоанский",
            "sma": "южносаамский",
            "smj": "луле-саамский",
            "smn": "инари-саамский",
            "sms": "колтта-саамский",
            "sn": "шона",
            "snk": "сонинке",
            "so": "сомали",
            "sog": "согдийский",
            "sq": "албанский",
            "sr": "сербский",
            "srn": "сранан-тонго",
            "srr": "серер",
            "ss": "свази",
            "ssy": "сахо",
            "st": "южный сото",
            "su": "сунданский",
            "suk": "сукума",
            "sus": "сусу",
            "sux": "шумерский",
            "sv": "шведский",
            "sw": "суахили",
            "sw-CD": "конголезский суахили",
            "swb": "коморский",
            "syc": "классический сирийский",
            "syr": "сирийский",
            "ta": "тамильский",
            "te": "телугу",
            "tem": "темне",
            "teo": "тесо",
            "ter": "терено",
            "tet": "тетум",
            "tg": "таджикский",
            "th": "тайский",
            "ti": "тигринья",
            "tig": "тигре",
            "tiv": "тиви",
            "tk": "туркменский",
            "tkl": "токелайский",
            "tl": "тагалог",
            "tlh": "клингонский",
            "tli": "тлингит",
            "tmh": "тамашек",
            "tn": "тсвана",
            "to": "тонганский",
            "tog": "тонга",
            "tpi": "ток-писин",
            "tr": "турецкий",
            "tru": "туройо",
            "trv": "седекский",
            "ts": "тсонга",
            "tsi": "цимшиан",
            "tt": "татарский",
            "tum": "тумбука",
            "tvl": "тувалу",
            "tw": "тви",
            "twq": "тасавак",
            "ty": "таитянский",
            "tyv": "тувинский",
            "tzm": "среднеатласский тамазигхтский",
            "udm": "удмуртский",
            "ug": "уйгурский",
            "uga": "угаритский",
            "uk": "украинский",
            "umb": "умбунду",
            "und": "неизвестный язык",
            "ur": "урду",
            "uz": "узбекский",
            "vai": "ваи",
            "ve": "венда",
            "vi": "вьетнамский",
            "vo": "волапюк",
            "vot": "водский",
            "vun": "вунджо",
            "wa": "валлонский",
            "wae": "валлисский",
            "wal": "воламо",
            "war": "варай",
            "was": "вашо",
            "wbp": "вальбири",
            "wo": "волоф",
            "wuu": "ву",
            "xal": "калмыцкий",
            "xh": "коса",
            "xog": "сога",
            "yao": "яо",
            "yap": "яп",
            "yav": "янгбен",
            "ybb": "йемба",
            "yi": "идиш",
            "yo": "йоруба",
            "yrl": "yrl",
            "yue": "кантонский",
            "za": "чжуань",
            "zap": "сапотекский",
            "zbl": "блиссимволика",
            "zen": "зенагский",
            "zgh": "тамазигхтский",
            "zh": "китайский",
            "zh-Hans": "китайский, упрощенное письмо",
            "zh-Hant": "китайский, традиционное письмо",
            "zu": "зулу",
            "zun": "зуньи",
            "zxx": "нет языкового материала",
            "zza": "заза"
          },
          "narrow": {
          },
          "short": {
            "az": "азербайджанский",
            "en-GB": "британский английский",
            "en-US": "американский английский"
          }
        },
        "standard": {
          "long": {
            "aa": "афарский",
            "ab": "абхазский",
            "ace": "ачехский",
            "ach": "ачоли",
            "ada": "адангме",
            "ady": "адыгейский",
            "ae": "авестийский",
            "af": "африкаанс",
            "afh": "африхили",
            "agq": "агем",
            "ain": "айнский",
            "ak": "акан",
            "akk": "аккадский",
            "ale": "алеутский",
            "alt": "южноалтайский",
            "am": "амхарский",
            "an": "арагонский",
            "ang": "староанглийский",
            "anp": "ангика",
            "ar": "арабский",
            "ar-001": "арабский (весь мир)",
            "arc": "арамейский",
            "arn": "мапуче",
            "arp": "арапахо",
            "ars": "недждийский арабский",
            "arw": "аравакский",
            "as": "ассамский",
            "asa": "асу",
            "ast": "астурийский",
            "av": "аварский",
            "awa": "авадхи",
            "ay": "аймара",
            "az": "азербайджанский",
            "ba": "башкирский",
            "bal": "белуджский",
            "ban": "балийский",
            "bas": "баса",
            "bax": "бамум",
            "bbj": "гомала",
            "be": "белорусский",
            "bej": "беджа",
            "bem": "бемба",
            "bez": "бена",
            "bfd": "бафут",
            "bg": "болгарский",
            "bgn": "западный белуджский",
            "bho": "бходжпури",
            "bi": "бислама",
            "bik": "бикольский",
            "bin": "бини",
            "bkm": "ком",
            "bla": "сиксика",
            "bm": "бамбара",
            "bn": "бенгальский",
            "bo": "тибетский",
            "br": "бретонский",
            "bra": "брауи",
            "brx": "бодо",
            "bs": "боснийский",
            "bss": "акоосе",
            "bua": "бурятский",
            "bug": "бугийский",
            "bum": "булу",
            "byn": "билин",
            "byv": "медумба",
            "ca": "каталанский",
            "cad": "каддо",
            "car": "кариб",
            "cay": "кайюга",
            "cch": "атсам",
            "ccp": "чакма",
            "ce": "чеченский",
            "ceb": "себуано",
            "cgg": "кига",
            "ch": "чаморро",
            "chb": "чибча",
            "chg": "чагатайский",
            "chk": "чукотский",
            "chm": "марийский",
            "chn": "чинук жаргон",
            "cho": "чоктавский",
            "chp": "чипевьян",
            "chr": "чероки",
            "chy": "шайенский",
            "ckb": "центральнокурдский",
            "co": "корсиканский",
            "cop": "коптский",
            "cr": "кри",
            "crh": "крымско-татарский",
            "crs": "сейшельский креольский",
            "cs": "чешский",
            "csb": "кашубский",
            "cu": "церковнославянский",
            "cv": "чувашский",
            "cy": "валлийский",
            "da": "датский",
            "dak": "дакота",
            "dar": "даргинский",
            "dav": "таита",
            "de": "немецкий",
            "de-AT": "немецкий (Австрия)",
            "de-CH": "немецкий (Швейцария)",
            "del": "делаварский",
            "den": "слейви",
            "dgr": "догриб",
            "din": "динка",
            "dje": "джерма",
            "doi": "догри",
            "dsb": "нижнелужицкий",
            "dua": "дуала",
            "dum": "средненидерландский",
            "dv": "мальдивский",
            "dyo": "диола-фоньи",
            "dyu": "диула",
            "dz": "дзонг-кэ",
            "dzg": "даза",
            "ebu": "эмбу",
            "ee": "эве",
            "efi": "эфик",
            "egy": "древнеегипетский",
            "eka": "экаджук",
            "el": "греческий",
            "elx": "эламский",
            "en": "английский",
            "en-AU": "английский (Австралия)",
            "en-CA": "английский (Канада)",
            "en-GB": "английский (Великобритания)",
            "en-US": "английский (Соединенные Штаты)",
            "enm": "среднеанглийский",
            "eo": "эсперанто",
            "es": "испанский",
            "es-419": "испанский (Латинская Америка)",
            "es-ES": "испанский (Испания)",
            "es-MX": "испанский (Мексика)",
            "et": "эстонский",
            "eu": "баскский",
            "ewo": "эвондо",
            "fa": "персидский",
            "fa-AF": "персидский (Афганистан)",
            "fan": "фанг",
            "fat": "фанти",
            "ff": "фулах",
            "fi": "финский",
            "fil": "филиппинский",
            "fj": "фиджи",
            "fo": "фарерский",
            "fon": "фон",
            "fr": "французский",
            "fr-CA": "французский (Канада)",
            "fr-CH": "французский (Швейцария)",
            "frc": "каджунский французский",
            "frm": "среднефранцузский",
            "fro": "старофранцузский",
            "frr": "северный фризский",
            "frs": "восточный фризский",
            "fur": "фриульский",
            "fy": "западнофризский",
            "ga": "ирландский",
            "gaa": "га",
            "gag": "гагаузский",
            "gan": "гань",
            "gay": "гайо",
            "gba": "гбая",
            "gd": "гэльский",
            "gez": "геэз",
            "gil": "гилбертский",
            "gl": "галисийский",
            "gmh": "средневерхненемецкий",
            "gn": "гуарани",
            "goh": "древневерхненемецкий",
            "gon": "гонди",
            "gor": "горонтало",
            "got": "готский",
            "grb": "гребо",
            "grc": "древнегреческий",
            "gsw": "швейцарский немецкий",
            "gu": "гуджарати",
            "guz": "гусии",
            "gv": "мэнский",
            "gwi": "гвичин",
            "ha": "хауса",
            "hai": "хайда",
            "hak": "хакка",
            "haw": "гавайский",
            "he": "иврит",
            "hi": "хинди",
            "hil": "хилигайнон",
            "hit": "хеттский",
            "hmn": "хмонг",
            "ho": "хиримоту",
            "hr": "хорватский",
            "hsb": "верхнелужицкий",
            "hsn": "сян",
            "ht": "гаитянский",
            "hu": "венгерский",
            "hup": "хупа",
            "hy": "армянский",
            "hz": "гереро",
            "ia": "интерлингва",
            "iba": "ибанский",
            "ibb": "ибибио",
            "id": "индонезийский",
            "ie": "интерлингве",
            "ig": "игбо",
            "ii": "носу",
            "ik": "инупиак",
            "ilo": "илоко",
            "inh": "ингушский",
            "io": "идо",
            "is": "исландский",
            "it": "итальянский",
            "iu": "инуктитут",
            "ja": "японский",
            "jbo": "ложбан",
            "jgo": "нгомба",
            "jmc": "мачаме",
            "jpr": "еврейско-персидский",
            "jrb": "еврейско-арабский",
            "jv": "яванский",
            "ka": "грузинский",
            "kaa": "каракалпакский",
            "kab": "кабильский",
            "kac": "качинский",
            "kaj": "каджи",
            "kam": "камба",
            "kaw": "кави",
            "kbd": "кабардинский",
            "kbl": "канембу",
            "kcg": "тьяп",
            "kde": "маконде",
            "kea": "кабувердьяну",
            "kfo": "коро",
            "kg": "конго",
            "kgp": "kgp",
            "kha": "кхаси",
            "kho": "хотанский",
            "khq": "койра чиини",
            "ki": "кикуйю",
            "kj": "кунама",
            "kk": "казахский",
            "kkj": "како",
            "kl": "гренландский",
            "kln": "календжин",
            "km": "кхмерский",
            "kmb": "кимбунду",
            "kn": "каннада",
            "ko": "корейский",
            "koi": "коми-пермяцкий",
            "kok": "конкани",
            "kos": "косраенский",
            "kpe": "кпелле",
            "kr": "канури",
            "krc": "карачаево-балкарский",
            "krl": "карельский",
            "kru": "курух",
            "ks": "кашмири",
            "ksb": "шамбала",
            "ksf": "бафия",
            "ksh": "кёльнский",
            "ku": "курдский",
            "kum": "кумыкский",
            "kut": "кутенаи",
            "kv": "коми",
            "kw": "корнский",
            "ky": "киргизский",
            "la": "латинский",
            "lad": "ладино",
            "lag": "ланго",
            "lah": "лахнда",
            "lam": "ламба",
            "lb": "люксембургский",
            "lez": "лезгинский",
            "lg": "ганда",
            "li": "лимбургский",
            "lij": "lij",
            "lkt": "лакота",
            "ln": "лингала",
            "lo": "лаосский",
            "lol": "монго",
            "lou": "луизианский креольский",
            "loz": "лози",
            "lrc": "севернолурский",
            "lt": "литовский",
            "lu": "луба-катанга",
            "lua": "луба-лулуа",
            "lui": "луисеньо",
            "lun": "лунда",
            "luo": "луо",
            "lus": "мизо",
            "luy": "лухья",
            "lv": "латышский",
            "mad": "мадурский",
            "maf": "мафа",
            "mag": "магахи",
            "mai": "майтхили",
            "mak": "макассарский",
            "man": "мандинго",
            "mas": "масаи",
            "mde": "маба",
            "mdf": "мокшанский",
            "mdr": "мандарский",
            "men": "менде",
            "mer": "меру",
            "mfe": "маврикийский креольский",
            "mg": "малагасийский",
            "mga": "среднеирландский",
            "mgh": "макуа-меетто",
            "mgo": "мета",
            "mh": "маршалльский",
            "mi": "маори",
            "mic": "микмак",
            "min": "минангкабау",
            "mk": "македонский",
            "ml": "малаялам",
            "mn": "монгольский",
            "mnc": "маньчжурский",
            "mni": "манипурский",
            "moh": "мохаук",
            "mos": "моси",
            "mr": "маратхи",
            "ms": "малайский",
            "mt": "мальтийский",
            "mua": "мунданг",
            "mul": "языки разных семей",
            "mus": "крик",
            "mwl": "мирандский",
            "mwr": "марвари",
            "my": "бирманский",
            "mye": "миене",
            "myv": "эрзянский",
            "mzn": "мазандеранский",
            "na": "науру",
            "nan": "миньнань",
            "nap": "неаполитанский",
            "naq": "нама",
            "nb": "норвежский букмол",
            "nd": "северный ндебеле",
            "nds": "нижненемецкий",
            "nds-NL": "нижненемецкий (Нидерланды)",
            "ne": "непальский",
            "new": "неварский",
            "ng": "ндонга",
            "nia": "ниас",
            "niu": "ниуэ",
            "nl": "нидерландский",
            "nl-BE": "нидерландский (Бельгия)",
            "nmg": "квасио",
            "nn": "нюнорск",
            "nnh": "нгиембунд",
            "no": "норвежский",
            "nog": "ногайский",
            "non": "старонорвежский",
            "nqo": "нко",
            "nr": "южный ндебеле",
            "nso": "северный сото",
            "nus": "нуэр",
            "nv": "навахо",
            "nwc": "классический невари",
            "ny": "ньянджа",
            "nym": "ньямвези",
            "nyn": "ньянколе",
            "nyo": "ньоро",
            "nzi": "нзима",
            "oc": "окситанский",
            "oj": "оджибва",
            "om": "оромо",
            "or": "ория",
            "os": "осетинский",
            "osa": "оседжи",
            "ota": "старотурецкий",
            "pa": "панджаби",
            "pag": "пангасинан",
            "pal": "пехлевийский",
            "pam": "пампанга",
            "pap": "папьяменто",
            "pau": "палау",
            "pcm": "нигерийско-креольский",
            "peo": "староперсидский",
            "phn": "финикийский",
            "pi": "пали",
            "pl": "польский",
            "pon": "понапе",
            "prg": "прусский",
            "pro": "старопровансальский",
            "ps": "пушту",
            "pt": "португальский",
            "pt-BR": "португальский (Бразилия)",
            "pt-PT": "португальский (Португалия)",
            "qu": "кечуа",
            "quc": "киче",
            "raj": "раджастхани",
            "rap": "рапануйский",
            "rar": "раротонга",
            "rhg": "рохинджа",
            "rm": "романшский",
            "rn": "рунди",
            "ro": "румынский",
            "ro-MD": "румынский (Молдова)",
            "rof": "ромбо",
            "rom": "цыганский",
            "ru": "русский",
            "rup": "арумынский",
            "rw": "киньяруанда",
            "rwk": "руанда",
            "sa": "санскрит",
            "sad": "сандаве",
            "sah": "саха",
            "sam": "самаритянский арамейский",
            "saq": "самбуру",
            "sas": "сасакский",
            "sat": "сантали",
            "sba": "нгамбайский",
            "sbp": "сангу",
            "sc": "сардинский",
            "scn": "сицилийский",
            "sco": "шотландский",
            "sd": "синдхи",
            "sdh": "южнокурдский",
            "se": "северносаамский",
            "see": "сенека",
            "seh": "сена",
            "sel": "селькупский",
            "ses": "койраборо сенни",
            "sg": "санго",
            "sga": "староирландский",
            "sh": "сербскохорватский",
            "shi": "ташельхит",
            "shn": "шанский",
            "shu": "чадский арабский",
            "si": "сингальский",
            "sid": "сидама",
            "sk": "словацкий",
            "sl": "словенский",
            "sm": "самоанский",
            "sma": "южносаамский",
            "smj": "луле-саамский",
            "smn": "инари-саамский",
            "sms": "колтта-саамский",
            "sn": "шона",
            "snk": "сонинке",
            "so": "сомали",
            "sog": "согдийский",
            "sq": "албанский",
            "sr": "сербский",
            "srn": "сранан-тонго",
            "srr": "серер",
            "ss": "свази",
            "ssy": "сахо",
            "st": "южный сото",
            "su": "сунданский",
            "suk": "сукума",
            "sus": "сусу",
            "sux": "шумерский",
            "sv": "шведский",
            "sw": "суахили",
            "sw-CD": "суахили (Конго - Киншаса)",
            "swb": "коморский",
            "syc": "классический сирийский",
            "syr": "сирийский",
            "ta": "тамильский",
            "te": "телугу",
            "tem": "темне",
            "teo": "тесо",
            "ter": "терено",
            "tet": "тетум",
            "tg": "таджикский",
            "th": "тайский",
            "ti": "тигринья",
            "tig": "тигре",
            "tiv": "тиви",
            "tk": "туркменский",
            "tkl": "токелайский",
            "tl": "тагалог",
            "tlh": "клингонский",
            "tli": "тлингит",
            "tmh": "тамашек",
            "tn": "тсвана",
            "to": "тонганский",
            "tog": "тонга",
            "tpi": "ток-писин",
            "tr": "турецкий",
            "tru": "туройо",
            "trv": "седекский",
            "ts": "тсонга",
            "tsi": "цимшиан",
            "tt": "татарский",
            "tum": "тумбука",
            "tvl": "тувалу",
            "tw": "тви",
            "twq": "тасавак",
            "ty": "таитянский",
            "tyv": "тувинский",
            "tzm": "среднеатласский тамазигхтский",
            "udm": "удмуртский",
            "ug": "уйгурский",
            "uga": "угаритский",
            "uk": "украинский",
            "umb": "умбунду",
            "und": "неизвестный язык",
            "ur": "урду",
            "uz": "узбекский",
            "vai": "ваи",
            "ve": "венда",
            "vi": "вьетнамский",
            "vo": "волапюк",
            "vot": "водский",
            "vun": "вунджо",
            "wa": "валлонский",
            "wae": "валлисский",
            "wal": "воламо",
            "war": "варай",
            "was": "вашо",
            "wbp": "вальбири",
            "wo": "волоф",
            "wuu": "ву",
            "xal": "калмыцкий",
            "xh": "коса",
            "xog": "сога",
            "yao": "яо",
            "yap": "яп",
            "yav": "янгбен",
            "ybb": "йемба",
            "yi": "идиш",
            "yo": "йоруба",
            "yrl": "yrl",
            "yue": "кантонский",
            "za": "чжуань",
            "zap": "сапотекский",
            "zbl": "блиссимволика",
            "zen": "зенагский",
            "zgh": "тамазигхтский",
            "zh": "китайский",
            "zh-Hans": "китайский, упрощенное письмо",
            "zh-Hant": "китайский, традиционное письмо",
            "zu": "зулу",
            "zun": "зуньи",
            "zxx": "нет языкового материала",
            "zza": "заза"
          },
          "narrow": {
          },
          "short": {
            "az": "азербайджанский",
            "en-GB": "английский (Британия)",
            "en-US": "английский (США)"
          }
        }
      },
      "region": {
        "long": {
          "001": "весь мир",
          "002": "Африка",
          "003": "Северная Америка",
          "005": "Южная Америка",
          "009": "Океания",
          "011": "Западная Африка",
          "013": "Центральная Америка",
          "014": "Восточная Африка",
          "015": "Северная Африка",
          "017": "Центральная Африка",
          "018": "Южная Африка",
          "019": "Америка",
          "021": "Североамериканский регион",
          "029": "Карибы",
          "030": "Восточная Азия",
          "034": "Южная Азия",
          "035": "Юго-Восточная Азия",
          "039": "Южная Европа",
          "053": "Австралазия",
          "054": "Меланезия",
          "057": "Микронезия",
          "061": "Полинезия",
          "142": "Азия",
          "143": "Центральная Азия",
          "145": "Западная Азия",
          "150": "Европа",
          "151": "Восточная Европа",
          "154": "Северная Европа",
          "155": "Западная Европа",
          "202": "Тропическая Африка",
          "419": "Латинская Америка",
          "AC": "о-в Вознесения",
          "AD": "Андорра",
          "AE": "ОАЭ",
          "AF": "Афганистан",
          "AG": "Антигуа и Барбуда",
          "AI": "Ангилья",
          "AL": "Албания",
          "AM": "Армения",
          "AO": "Ангола",
          "AQ": "Антарктида",
          "AR": "Аргентина",
          "AS": "Американское Самоа",
          "AT": "Австрия",
          "AU": "Австралия",
          "AW": "Аруба",
          "AX": "Аландские о-ва",
          "AZ": "Азербайджан",
          "BA": "Босния и Герцеговина",
          "BB": "Барбадос",
          "BD": "Бангладеш",
          "BE": "Бельгия",
          "BF": "Буркина-Фасо",
          "BG": "Болгария",
          "BH": "Бахрейн",
          "BI": "Бурунди",
          "BJ": "Бенин",
          "BL": "Сен-Бартелеми",
          "BM": "Бермудские о-ва",
          "BN": "Бруней-Даруссалам",
          "BO": "Боливия",
          "BQ": "Бонэйр, Синт-Эстатиус и Саба",
          "BR": "Бразилия",
          "BS": "Багамы",
          "BT": "Бутан",
          "BV": "о-в Буве",
          "BW": "Ботсвана",
          "BY": "Беларусь",
          "BZ": "Белиз",
          "CA": "Канада",
          "CC": "Кокосовые о-ва",
          "CD": "Конго - Киншаса",
          "CF": "Центрально-Африканская Республика",
          "CG": "Конго - Браззавиль",
          "CH": "Швейцария",
          "CI": "Кот-д’Ивуар",
          "CK": "Острова Кука",
          "CL": "Чили",
          "CM": "Камерун",
          "CN": "Китай",
          "CO": "Колумбия",
          "CP": "о-в Клиппертон",
          "CR": "Коста-Рика",
          "CU": "Куба",
          "CV": "Кабо-Верде",
          "CW": "Кюрасао",
          "CX": "о-в Рождества",
          "CY": "Кипр",
          "CZ": "Чехия",
          "DE": "Германия",
          "DG": "Диего-Гарсия",
          "DJ": "Джибути",
          "DK": "Дания",
          "DM": "Доминика",
          "DO": "Доминиканская Республика",
          "DZ": "Алжир",
          "EA": "Сеута и Мелилья",
          "EC": "Эквадор",
          "EE": "Эстония",
          "EG": "Египет",
          "EH": "Западная Сахара",
          "ER": "Эритрея",
          "ES": "Испания",
          "ET": "Эфиопия",
          "EU": "Европейский союз",
          "EZ": "еврозона",
          "FI": "Финляндия",
          "FJ": "Фиджи",
          "FK": "Фолклендские о-ва",
          "FM": "Федеративные Штаты Микронезии",
          "FO": "Фарерские о-ва",
          "FR": "Франция",
          "GA": "Габон",
          "GB": "Великобритания",
          "GD": "Гренада",
          "GE": "Грузия",
          "GF": "Французская Гвиана",
          "GG": "Гернси",
          "GH": "Гана",
          "GI": "Гибралтар",
          "GL": "Гренландия",
          "GM": "Гамбия",
          "GN": "Гвинея",
          "GP": "Гваделупа",
          "GQ": "Экваториальная Гвинея",
          "GR": "Греция",
          "GS": "Южная Георгия и Южные Сандвичевы о-ва",
          "GT": "Гватемала",
          "GU": "Гуам",
          "GW": "Гвинея-Бисау",
          "GY": "Гайана",
          "HK": "Гонконг (САР)",
          "HM": "о-ва Херд и Макдональд",
          "HN": "Гондурас",
          "HR": "Хорватия",
          "HT": "Гаити",
          "HU": "Венгрия",
          "IC": "Канарские о-ва",
          "ID": "Индонезия",
          "IE": "Ирландия",
          "IL": "Израиль",
          "IM": "о-в Мэн",
          "IN": "Индия",
          "IO": "Британская территория в Индийском океане",
          "IQ": "Ирак",
          "IR": "Иран",
          "IS": "Исландия",
          "IT": "Италия",
          "JE": "Джерси",
          "JM": "Ямайка",
          "JO": "Иордания",
          "JP": "Япония",
          "KE": "Кения",
          "KG": "Киргизия",
          "KH": "Камбоджа",
          "KI": "Кирибати",
          "KM": "Коморы",
          "KN": "Сент-Китс и Невис",
          "KP": "КНДР",
          "KR": "Республика Корея",
          "KW": "Кувейт",
          "KY": "Острова Кайман",
          "KZ": "Казахстан",
          "LA": "Лаос",
          "LB": "Ливан",
          "LC": "Сент-Люсия",
          "LI": "Лихтенштейн",
          "LK": "Шри-Ланка",
          "LR": "Либерия",
          "LS": "Лесото",
          "LT": "Литва",
          "LU": "Люксембург",
          "LV": "Латвия",
          "LY": "Ливия",
          "MA": "Марокко",
          "MC": "Монако",
          "MD": "Молдова",
          "ME": "Черногория",
          "MF": "Сен-Мартен",
          "MG": "Мадагаскар",
          "MH": "Маршалловы Острова",
          "MK": "Северная Македония",
          "ML": "Мали",
          "MM": "Мьянма (Бирма)",
          "MN": "Монголия",
          "MO": "Макао (САР)",
          "MP": "Северные Марианские о-ва",
          "MQ": "Мартиника",
          "MR": "Мавритания",
          "MS": "Монтсеррат",
          "MT": "Мальта",
          "MU": "Маврикий",
          "MV": "Мальдивы",
          "MW": "Малави",
          "MX": "Мексика",
          "MY": "Малайзия",
          "MZ": "Мозамбик",
          "NA": "Намибия",
          "NC": "Новая Каледония",
          "NE": "Нигер",
          "NF": "о-в Норфолк",
          "NG": "Нигерия",
          "NI": "Никарагуа",
          "NL": "Нидерланды",
          "NO": "Норвегия",
          "NP": "Непал",
          "NR": "Науру",
          "NU": "Ниуэ",
          "NZ": "Новая Зеландия",
          "OM": "Оман",
          "PA": "Панама",
          "PE": "Перу",
          "PF": "Французская Полинезия",
          "PG": "Папуа — Новая Гвинея",
          "PH": "Филиппины",
          "PK": "Пакистан",
          "PL": "Польша",
          "PM": "Сен-Пьер и Микелон",
          "PN": "о-ва Питкэрн",
          "PR": "Пуэрто-Рико",
          "PS": "Палестинские территории",
          "PT": "Португалия",
          "PW": "Палау",
          "PY": "Парагвай",
          "QA": "Катар",
          "QO": "Внешняя Океания",
          "RE": "Реюньон",
          "RO": "Румыния",
          "RS": "Сербия",
          "RU": "Россия",
          "RW": "Руанда",
          "SA": "Саудовская Аравия",
          "SB": "Соломоновы Острова",
          "SC": "Сейшельские Острова",
          "SD": "Судан",
          "SE": "Швеция",
          "SG": "Сингапур",
          "SH": "о-в Св. Елены",
          "SI": "Словения",
          "SJ": "Шпицберген и Ян-Майен",
          "SK": "Словакия",
          "SL": "Сьерра-Леоне",
          "SM": "Сан-Марино",
          "SN": "Сенегал",
          "SO": "Сомали",
          "SR": "Суринам",
          "SS": "Южный Судан",
          "ST": "Сан-Томе и Принсипи",
          "SV": "Сальвадор",
          "SX": "Синт-Мартен",
          "SY": "Сирия",
          "SZ": "Эсватини",
          "TA": "Тристан-да-Кунья",
          "TC": "о-ва Тёркс и Кайкос",
          "TD": "Чад",
          "TF": "Французские Южные территории",
          "TG": "Того",
          "TH": "Таиланд",
          "TJ": "Таджикистан",
          "TK": "Токелау",
          "TL": "Восточный Тимор",
          "TM": "Туркменистан",
          "TN": "Тунис",
          "TO": "Тонга",
          "TR": "Турция",
          "TT": "Тринидад и Тобаго",
          "TV": "Тувалу",
          "TW": "Тайвань",
          "TZ": "Танзания",
          "UA": "Украина",
          "UG": "Уганда",
          "UM": "Внешние малые о-ва (США)",
          "UN": "Организация Объединенных Наций",
          "US": "Соединенные Штаты",
          "UY": "Уругвай",
          "UZ": "Узбекистан",
          "VA": "Ватикан",
          "VC": "Сент-Винсент и Гренадины",
          "VE": "Венесуэла",
          "VG": "Виргинские о-ва (Великобритания)",
          "VI": "Виргинские о-ва (США)",
          "VN": "Вьетнам",
          "VU": "Вануату",
          "WF": "Уоллис и Футуна",
          "WS": "Самоа",
          "XA": "псевдоакценты",
          "XB": "псевдо-Bidi",
          "XK": "Косово",
          "YE": "Йемен",
          "YT": "Майотта",
          "ZA": "Южно-Африканская Республика",
          "ZM": "Замбия",
          "ZW": "Зимбабве",
          "ZZ": "неизвестный регион"
        },
        "narrow": {
        },
        "short": {
          "GB": "Британия",
          "HK": "Гонконг",
          "MO": "Макао",
          "PS": "Палестина",
          "UN": "ООН",
          "US": "США"
        }
      },
      "script": {
        "long": {
          "Adlm": "Adlm",
          "Afak": "афака",
          "Aghb": "Aghb",
          "Ahom": "Ahom",
          "Arab": "арабица",
          "Aran": "насталик",
          "Armi": "арамейская",
          "Armn": "армянская",
          "Avst": "авестийская",
          "Bali": "балийская",
          "Bamu": "бамум",
          "Bass": "басса (вах)",
          "Batk": "батакская",
          "Beng": "бенгальская",
          "Bhks": "Bhks",
          "Blis": "блиссимволика",
          "Bopo": "бопомофо",
          "Brah": "брахми",
          "Brai": "шрифт Брайля",
          "Bugi": "бугинизийская",
          "Buhd": "бухид",
          "Cakm": "чакмийская",
          "Cans": "канадское слоговое письмо",
          "Cari": "карийская",
          "Cham": "чамская",
          "Cher": "чероки",
          "Chrs": "Chrs",
          "Cirt": "кирт",
          "Copt": "коптская",
          "Cpmn": "Cpmn",
          "Cprt": "кипрская",
          "Cyrl": "кириллица",
          "Cyrs": "старославянская",
          "Deva": "деванагари",
          "Diak": "Diak",
          "Dogr": "Dogr",
          "Dsrt": "дезерет",
          "Dupl": "дуплоянская скоропись",
          "Egyd": "египетская демотическая",
          "Egyh": "египетская иератическая",
          "Egyp": "египетская иероглифическая",
          "Elba": "Elba",
          "Elym": "Elym",
          "Ethi": "эфиопская",
          "Geok": "грузинская хуцури",
          "Geor": "грузинская",
          "Glag": "глаголица",
          "Gong": "Gong",
          "Gonm": "Gonm",
          "Goth": "готская",
          "Gran": "грантха",
          "Grek": "греческая",
          "Gujr": "гуджарати",
          "Guru": "гурмукхи",
          "Hanb": "ханьб",
          "Hang": "хангыль",
          "Hani": "китайская",
          "Hano": "хануну",
          "Hans": "упрощенная китайская",
          "Hant": "традиционная китайская",
          "Hatr": "Hatr",
          "Hebr": "еврейская",
          "Hira": "хирагана",
          "Hluw": "лувийские иероглифы",
          "Hmng": "пахау хмонг",
          "Hmnp": "Hmnp",
          "Hrkt": "катакана и хирагана",
          "Hung": "старовенгерская",
          "Inds": "хараппская (письменность долины Инда)",
          "Ital": "староитальянская",
          "Jamo": "джамо",
          "Java": "яванская",
          "Jpan": "японская",
          "Jurc": "чжурчжэньская",
          "Kali": "кайа",
          "Kana": "катакана",
          "Khar": "кхароштхи",
          "Khmr": "кхмерская",
          "Khoj": "ходжики",
          "Kits": "Kits",
          "Knda": "каннада",
          "Kore": "корейская",
          "Kpel": "кпелле",
          "Kthi": "кайтхи",
          "Lana": "ланна",
          "Laoo": "лаосская",
          "Latf": "латинская фрактура",
          "Latg": "гэльская латинская",
          "Latn": "латиница",
          "Lepc": "лепха",
          "Limb": "лимбу",
          "Lina": "линейное письмо А",
          "Linb": "линейное письмо Б",
          "Lisu": "лису",
          "Loma": "лома",
          "Lyci": "лициан",
          "Lydi": "лидийская",
          "Mahj": "Mahj",
          "Maka": "Maka",
          "Mand": "мандейская",
          "Mani": "манихейская",
          "Marc": "Marc",
          "Maya": "майя",
          "Medf": "Medf",
          "Mend": "менде",
          "Merc": "мероитская курсивная",
          "Mero": "мероитская",
          "Mlym": "малаялам",
          "Modi": "Modi",
          "Mong": "монгольская",
          "Moon": "азбука муна",
          "Mroo": "мро",
          "Mtei": "манипури",
          "Mult": "Mult",
          "Mymr": "мьянманская",
          "Nand": "Nand",
          "Narb": "северноаравийское",
          "Nbat": "набатейская",
          "Newa": "Newa",
          "Nkgb": "наси геба",
          "Nkoo": "нко",
          "Nshu": "нюй-шу",
          "Ogam": "огамическая",
          "Olck": "ол чики",
          "Orkh": "орхоно-енисейская",
          "Orya": "ория",
          "Osge": "Osge",
          "Osma": "османская",
          "Ougr": "Ougr",
          "Palm": "пальмиры",
          "Pauc": "Pauc",
          "Perm": "древнепермская",
          "Phag": "пагспа",
          "Phli": "пехлевийская",
          "Phlp": "пахлави псалтирная",
          "Phlv": "пахлави книжная",
          "Phnx": "финикийская",
          "Plrd": "поллардовская фонетика",
          "Prti": "парфянская",
          "Qaag": "зоджи",
          "Rjng": "реджангская",
          "Rohg": "Rohg",
          "Roro": "ронго-ронго",
          "Runr": "руническая",
          "Samr": "самаритянская",
          "Sara": "сарати",
          "Sarb": "староюжноарабская",
          "Saur": "саураштра",
          "Sgnw": "язык знаков",
          "Shaw": "алфавит Шоу",
          "Shrd": "шарада",
          "Sidd": "Sidd",
          "Sind": "кхудавади",
          "Sinh": "сингальская",
          "Sogd": "Sogd",
          "Sogo": "Sogo",
          "Sora": "сора-сонпенг",
          "Soyo": "Soyo",
          "Sund": "сунданская",
          "Sylo": "силоти нагри",
          "Syrc": "сирийская",
          "Syre": "сирийская эстрангело",
          "Syrj": "западносирийская",
          "Syrn": "восточно-сирийская",
          "Tagb": "тагбанва",
          "Takr": "такри",
          "Tale": "тайский ле",
          "Talu": "новый тайский ле",
          "Taml": "тамильская",
          "Tang": "тангутское меня",
          "Tavt": "тай-вьет",
          "Telu": "телугу",
          "Teng": "тенгварская",
          "Tfng": "древнеливийская",
          "Tglg": "тагалог",
          "Thaa": "тана",
          "Thai": "тайская",
          "Tibt": "тибетская",
          "Tirh": "тирхута",
          "Tnsa": "Tnsa",
          "Toto": "Toto",
          "Ugar": "угаритская",
          "Vaii": "вайская",
          "Visp": "видимая речь",
          "Vith": "Vith",
          "Wara": "варанг-кшити",
          "Wcho": "Wcho",
          "Wole": "волеаи",
          "Xpeo": "староперсидская",
          "Xsux": "шумеро-аккадская клинопись",
          "Yezi": "Yezi",
          "Yiii": "и",
          "Zanb": "Zanb",
          "Zinh": "унаследованная",
          "Zmth": "математические обозначения",
          "Zsye": "эмодзи",
          "Zsym": "символы",
          "Zxxx": "нет письменности",
          "Zyyy": "общепринятая",
          "Zzzz": "неизвестная письменность"
        },
        "narrow": {
        },
        "short": {
        }
      }
    }
  },
  "locale": "ru"
})
}